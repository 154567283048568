import { ThunkAction } from "redux-thunk";
const ls = require('local-storage');

export enum EFilterGroups {
    status = "status",
    owner = "owner",
    resource = "resource",
    template = "template"
}

export enum EAccountRoles {
    admin = 'admin',
    user = 'user',
    viewer = 'viewer'
}

export interface IBasicOption {
    value: string;
    label: string;
}

export interface IGroupedOptions {
    [group: string]: IBasicOption[]
}

export interface Resource {
    id: string;
    name: string;
    type: string;
    maximum?: number;
    warning?: number;
    email?: string;
}

export interface TemplateResourceAllocation {
    id: string;
    name: string;
    type: string;
    quantity: number;
    new: boolean;
}

export interface EventResourceAllocation {
    id: string;
    name: string;
    type: string;
    quantity: number;
    scheduling: {
        usesEventReserve: boolean;
        reserveStart: string;
        reserveEnd: string;
    };
    new: boolean;
}

export interface Account {
    username: string;
    name: string;
    email: string;
    status: string;
    role: EAccountRoles
}

export interface Template {
    id: string;
    name: string;
    resources: TemplateResourceAllocation[]
}

export interface ConflictEvent {
    event: Event,
    eventId: string,
    eventName: string,
    quantity: number
}

export interface Conflict {
    start: string;
    end: string;
    max: number;
    used: number;
    requested: number;
    events: ConflictEvent[];
}

export interface ResourceConflicts {
    resource: Resource,
    conflicts: Conflict[]
}

export interface Event {
    id: string;
    name: string;
    status: string; // TODO: Convert to enum
    notify: boolean; // Set to true if notifications should go out, always false on retrieve
    book: boolean; // Set to true if should attempt to book now, always false on retrieve
    soldOut: boolean;
    notes?: string;
    scheduling: {
        date: string | Date;  // like '2019-07-22'
        eventStart: string; // like '13:15' or '09:30'
        eventEnd: string;
        reserveStart: string;
        reserveEnd: string;
    };
    owner: {
        id: string,
        name: string,
        email: string
    };
    template: {
        id: string,
        name: string
    };
    resources: EventResourceAllocation[];
    conflicts: ResourceConflicts[];
}

export interface EventCancellation {
    id: string;
    name: string;
}

export interface DateTimePicker {
    start_date: Date;
    end_date: Date;
}

export interface EventOptions {
    status: IBasicOption[];
    owner: IBasicOption[];
    resource: IGroupedOptions;
    template: IBasicOption[];
}

export interface EventFilters {
    status: string[];
    owner: string[];
    resource: string[];
    template: string[];
}
const storedFilters = ls.get('filters');
const defaultFilters = {
    status: [],
    resource: [],
    owner: [],
    template: [],
    ...storedFilters
}

export interface StoreState {
    user: Account;
    resources: Resource[];
    accounts: Account[];
    templates: Template[];
    events: Event[];
    filters: EventFilters;
}

export const defaultState: StoreState = {
    user: {
        username: '',
        email: '',
        name: '',
        status: '',
        role: EAccountRoles.viewer
    },
    resources: [],
    accounts: [],
    templates: [],
    events: [],
    filters: defaultFilters
};

export type FormObject = Event | Template | Resource | Account;

export const statusOptions: IBasicOption[] = [
    { value: 'pending', label: 'Pending'},
    { value: 'booked', label: 'Booked'},
    { value: 'conflict', label: 'Conflict'},
    { value: 'cancelled', label: 'Cancelled'},
];

export type ThunkType = ThunkAction<void, StoreState, void, any>
