import { EventFilters, defaultState, ThunkType } from '../types/StoreState';
import { TypedReducer, TypedAction, Reducer } from "redoodle";

export const SetFiltersActionType = "filters::set";

export const SetFilters = TypedAction.define(SetFiltersActionType)<EventFilters>();

function createReducer(): Reducer<EventFilters> {
    const builder = TypedReducer.builder<EventFilters>();

    builder.withHandler(SetFilters.TYPE, (state, payload) => {
        return payload;
    });

    builder.withDefaultHandler(state => {
        return state !== undefined ? state : defaultState.filters;
    });
    
    return builder.build();
}

export const eventFiltersReducer = createReducer();
