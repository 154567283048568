import { Event, defaultState, ThunkType, EventCancellation } from '../types/StoreState';
import { TypedReducer, TypedAction, Reducer } from "redoodle";
import { API } from "aws-amplify";

export const RefreshEvents = TypedAction.define("events::refresh")<{
    events: Event[];
}>();

export function refreshEventsDateRange(start: string, end: string): ThunkType {
    return async dispatch => {
        let response = {
            success: true,
            error: undefined
        }
 
        try {
            const range = (start <= end) ? `${start}/${end}` : `${end}/${start}`
            const events = await API.get('api', `/events/range/${range}`, {});
            dispatch(RefreshEvents({ events: events }));
        } catch (e) {
            response = {
                success: false,
                error: e.message
            };
        }

        return response;
    }
}

export function addEvent(event: Event): ThunkType {
    return async dispatch => {
        try {
            const result = await API.post('api', '/events/', { body: event });
        } catch (e) {
            alert(e.message);
        }
    }
}

export function updateEvent(event: Event): ThunkType {
    return async dispatch => {
        try {
            const result = await API.put('api', '/events/', { body: event });
        } catch (e) {
            alert(e.message);
        }
    }
}

export function deleteEvent(event: Event): ThunkType {
    return async dispatch => {
        try {
            const result = await API.del('api', '/events/', { body: event });
        } catch (e) {
            alert(e.message);
        }
    }
}

export function cancelEvent(event: EventCancellation): ThunkType {
    return async dispatch => {
        try {
            const result = await API.put('api', '/events/cancel', { body: event });
        } catch (e) {
            alert(e.message);
        }
    }
}

// This isn't actually used anywhere
export function getEvent(id: string): ThunkType {
    return async dispatch => {
        let response = {
            success: false,
            data: undefined,
            error: undefined
        }

        try {
            const result = await API.get('api', '/events/'+id, {});
            response = {
                success: true,
                data: result,
                error: undefined
            }
        } catch (e) {
            response = {
                success: false,
                data: undefined,
                error: e.message
            }
        }

        return response;
    }
}

function createReducer(): Reducer<Event[]> {
    const builder = TypedReducer.builder<Event[]>();

    builder.withHandler(RefreshEvents.TYPE, (state, payload) => {
        return payload.events;
    });

    builder.withDefaultHandler(state => {
        return state !== undefined ? state : defaultState.events;
    });
    
    return builder.build();
}

export const eventsReducer = createReducer();
