import React, { useState, useEffect } from "react";
import {useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import { StoreState } from "../../types/StoreState";
import '../../css/EventAdder.css';

export const EventAdder = () => {
    
    const templates = useSelector((state:StoreState) => state.templates);
    const [templateId, setTemplateId] = useState<string>('0');

    return (<form className="form-group float-right form-inline" id="form-adder">
        <select className="form-control mr-2" value={templateId} onChange={(event) => setTemplateId(event.target.value)}>
            <option key={0} value={0}>Blank Event</option>
            {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
        </select>
        <Link to={`/events/add`+(templateId !== '0' ? `/`+templateId : ``)} className="float-right btn btn-primary btn-sm">Add</Link>
    </form>);
}
