import React, { PureComponent, ReactNode } from "react";
import { Formik, FormikActions, FormikProps, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import { Account, StoreState, EAccountRoles } from '../../types/StoreState';
import { addAccount, updateAccount, refreshAccounts } from "../../reducers/AccountsReducers";
import { withRouter, RouteChildrenProps, match } from "react-router";
import { connect } from "react-redux";
import { ResultMessage } from "../../types/Messaging";

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .required('User name is required'),
    email: Yup.string()
        .required('User email is required')
        .email()
});

// Pure Component
interface DispatchProps {
    refreshAccounts: () => ResultMessage;
    addAccount: (account: Account) => void;
    updateAccount: (account: Account) => void;
}

interface UrlParams {
    username: string;
}

interface StateProps extends RouteChildrenProps {
    accounts: Account[],
    match: match<UrlParams>
}

interface AccountFormState {
    account?: Account
}

type AccountFormProps = DispatchProps & StateProps;

export class PureAccountForm extends PureComponent<AccountFormProps, AccountFormState> {

    constructor(props: any) {
        super(props);
        const { match: { params: { username } }, accounts } = this.props;
        this.state = {
            account: accounts.find((account) => (account.username == username))
        };
    }

    public render(): ReactNode {

        const { match: { params: { username } }, addAccount, updateAccount, history } = this.props;
        const { account } = this.state;

        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">{username ? `Edit` : `Add`} User</h3>
                    </div>

                    <div className="panel-content">
                        <Formik
                        enableReinitialize={true}
                        initialValues={account || {
                            username: '',
                            email: '',
                            name: '',
                            status: '',
                            role: EAccountRoles.user
                        }}
                        validationSchema={FormSchema}
                        onSubmit={async (values: Account, actions: FormikActions<Account>) => {
                            if (account) {
                                const result = await updateAccount(values);
                            } else {
                                const result = await addAccount(values);
                            }
                            actions.setSubmitting(false);
                            history.push('/users');
                        }}
                        render={(props: FormikProps<Account>) => (
                            <Form>
                                <Field
                                    name="name"
                                    render={({ field, form }: FieldProps<Account>) => (
                                        <div className="form-group">
                                            <label htmlFor="name">User Name</label>
                                            <input type="text" {...field} className={props.touched.name && props.errors.name ? `form-control is-invalid` : `form-control`} aria-describedby="accountNameHelp" />
                                            <small id="accountNameHelp" className="form-text text-muted">The user's full name</small>
                                            {props.touched.name && props.errors.name ? <small className="form-text text-danger">{props.errors.name}</small> : null}
                                        </div>
                                    )}
                                />
                                <Field
                                    name="email"
                                    render={({ field, form }: FieldProps<Account>) => (
                                        <div className="form-group">
                                            <label htmlFor="type">Email</label>
                                            <input type="text" {...field} className={props.touched.email && props.errors.email ? `form-control is-invalid` : `form-control`} aria-describedby="accountEmailHelp" />
                                            {props.touched.email && props.errors.email ? <small className="form-text text-danger">{props.errors.email}</small> : null}
                                        </div>
                                    )}
                                />
                                <Field
                                    name="role"
                                    render={({ field, form }: FieldProps<Account>) => (
                                        <div className="form-group">
                                            <label htmlFor="type">Role</label>
                                            <select {...field} className={props.touched.role && props.errors.email ? `form-control is-invalid` : `form-control`} aria-describedby="accountRoleHelp">
                                                {Object.values(EAccountRoles).map(role => (<option value={role} key={role}>{role.charAt(0).toUpperCase() + role.slice(1)}</option>))}
                                            </select>
                                            {props.touched.role && props.errors.role ? <small className="form-text text-danger">{props.errors.role}</small> : null}
                                        </div>
                                    )}
                                />
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </Form>
                        )} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ accounts }: StoreState) => {
    return { accounts: accounts };
};

const mapDispatchToProps = {
    refreshAccounts,
    updateAccount,
    addAccount
};

export const AccountForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureAccountForm) as any);
