import React, {PureComponent} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { NotFound } from './components/interface/NotFound';
import { Navigation } from './components/interface/Navigation';
import { Placeholder } from './components/interface/Placeholder';
import { ResourceTable } from './components/resource/ResourceTable';
import { ResourceForm } from './components/resource/ResourceForm';
import { AccountTable } from './components/account/AccountTable';
import { AccountForm } from './components/account/AccountForm';
import { TemplateTable } from './components/template/TemplateTable';
import { TemplateForm } from './components/template/TemplateForm';

import { EventCalendar } from './components/event/EventCalendar';
import { EventForm } from './components/event/EventForm';
import { EventTable } from './components/event/EventTable';

import { refreshResources } from './reducers/ResourcesReducers';
import { refreshAccounts } from './reducers/AccountsReducers';
import { refreshTemplates } from './reducers/TemplatesReducers';
import { updateUser } from './reducers/UserReducers';
import { Account, EAccountRoles, StoreState, defaultState } from './types/StoreState';

import Amplify from "@aws-amplify/core";
import Auth from '@aws-amplify/auth';
import {
    ConfirmSignIn,
    ForgotPassword,
    SignIn,
    VerifyContact,
    Loading,
    RequireNewPassword,
    withAuthenticator
} from "aws-amplify-react";
import config from './config';
import {theme} from './Theme';

Amplify.configure({
  Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
      endpoints: [
          {
              name: "api",
              endpoint: config.apiGateway.URL,
              region: config.apiGateway.REGION
          }
      ]
  },
  Analytics: { disabled: true }
});

// Pure Component
interface DispatchProps {
    refreshResources: () => void;
    refreshAccounts: () => void;
    refreshTemplates: () => void;
    updateUser: (user: Account) => void;
}

interface StateProps {
    accounts: Account[];
    user: Account;
    authState: string;
    errorMessage: (message:string) => string;
}

interface LocalState {
    needRefresh: boolean;
}

type AppProps = DispatchProps & StateProps;

class PureApp extends PureComponent<AppProps, LocalState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            needRefresh: true
        }
    }

    refresh = async () => {
        await Promise.all([
            Auth.currentUserInfo(),
            this.props.refreshResources(),
            this.props.refreshAccounts(),
            this.props.refreshTemplates()
        ]).then(values => {
            const username = values[0].username;
            const user = this.props.accounts.find(u => u.username === username);
            this.props.updateUser(user!);
            this.setState({ needRefresh: false });
        });
    }

    componentDidMount() {
        const { authState } = this.props;
        if (authState === 'signedIn' && this.state.needRefresh) {
            this.refresh();
        }

        if (authState !== 'signedIn') {
            this.props.updateUser(defaultState.user);
            this.setState({ needRefresh: true })
        }
    }

    render() {
        const { authState, user } = this.props;

        if (authState == 'signedIn') {

            const isAdmin = user && (user.role === EAccountRoles.admin);
            const isUser = isAdmin || (user && (user.role === EAccountRoles.user));

            return (
                <React.Fragment>
                    <Router>
                        <Route component={Navigation} />
                        {/*  Main Container Start  */}
                        <main className="main--container">
                            {/*  Main Content Start  */}
                            <section className="main--content" id="main-content">
                                <Switch>
                                    <Route path="/" exact render={props => <EventCalendar {...props} />} />
                                    <Route path="/events" exact render={props => <EventTable {...props} />} />
                                    <Route path="/events/add" exact render={props => { return isUser ? (<EventForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/events/add/:templateId" exact render={props => { return isUser ? (<EventForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/events/edit/:id" exact render={props => { return isUser ? (<EventForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/events/duplicate/:id" exact render={props => { return isUser ? (<EventForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/resources" exact render={props => { return isAdmin ? (<ResourceTable {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/resources/add" exact render={props => { return isAdmin ? (<ResourceForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/resources/edit/:id" exact render={props => { return isAdmin ? (<ResourceForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/templates" exact render={props => { return isAdmin ? (<TemplateTable {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/templates/add" exact render={props => { return isAdmin ? (<TemplateForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/templates/edit/:id" exact render={props => { return isAdmin ? (<TemplateForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/users" exact render={props => { return isAdmin ? (<AccountTable {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/users/add" exact render={props => { return isAdmin ? (<AccountForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Route path="/users/edit/:username" exact render={props => { return isAdmin ? (<AccountForm {...props} />) : (<Redirect to="/" />) }} />
                                    <Redirect to="/" />
                                </Switch>
                            </section>
                            {/*  Main Content End  */}

                            {/*  Main Footer Start  */}
                            <footer className="main--footer main--footer-dark">
                                <p>Copyright &copy; <a href="https://www.ventureindustriesonline.com">Venture Industries Online</a>. All Rights Reserved.</p>
                            </footer>
                            {/*  Main Footer End  */}
                        </main>
                        {/*  Main Container End  */}
                    </Router>
                </React.Fragment>
            );
        } else {
            return (<Router><Redirect to="/" /></Router>);
        }
    }
}

// Connector
const mapStateToProps = ({ accounts, user }: StoreState) => {
    return { accounts: accounts, user: user };
};

const mapDispatchToProps = {
    refreshResources,
    refreshAccounts,
    refreshTemplates,
    updateUser
};

export const App = withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(PureApp), false, [
    <SignIn />,
    <RequireNewPassword/>,
    <ConfirmSignIn />,
    <ForgotPassword />,
    <VerifyContact />,
    <Loading />
], null, theme);
