import { Resource, defaultState, ThunkType } from '../types/StoreState';
import { TypedReducer, TypedAction, Reducer } from "redoodle";
import { API } from "aws-amplify";

export const RefreshResources = TypedAction.define("resources::refresh")<{
    resources: Resource[];
}>();

export function refreshResources(): ThunkType {
    return async dispatch => {
        let response = {
            success: true,
            data: undefined,
            error: undefined
        }
 
        try {
            const resources = await API.get('api', '/resources/', {});
            dispatch(RefreshResources({ resources: resources }));
            response.data = resources;
        } catch (e) {
            response = {
                success: false,
                data: undefined,
                error: e.message
            };
        }

        return response;
    }
}

export function addResource(resource: Resource): ThunkType {
    return async dispatch => {
        try {
            const result = await API.post('api', '/resources/', { body: resource });
            refreshResources();
        } catch (e) {
            alert(e.message);
        }
    }
}

export function updateResource(resource: Resource): ThunkType {
    return async dispatch => {
        try {
            const result = await API.put('api', '/resources/', { body: resource });
            refreshResources();
        } catch (e) {
            alert(e.message);
        }
    }
}

function createReducer(): Reducer<Resource[]> {
    const builder = TypedReducer.builder<Resource[]>();

    builder.withHandler(RefreshResources.TYPE, (state, payload) => {
        return payload.resources;
    });

    builder.withDefaultHandler(state => {
        return state !== undefined ? state : defaultState.resources;
    });
    
    return builder.build();
}

export const resourcesReducer = createReducer();
