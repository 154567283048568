import React, { PureComponent, ReactNode } from "react";
import { FieldProps, getIn } from "formik";
import { Datepicker } from "react-formik-ui";
import { FormObject } from "../../types/StoreState";

interface LocalProps {
    name: string;
    label: string;
    help?: string;
    touched?: boolean;
    errors?: string;
    inline?: boolean;
}

type OwnProps = FieldProps<FormObject> & LocalProps;

export class FormGroupDatePicker extends PureComponent<OwnProps> {

    public render(): ReactNode {

        const { field, label, help, touched, errors, inline } = this.props;

        return (
            <div className="form-group">
                <label htmlFor={field.name}><span className="label-text">{label}</span></label>
                <Datepicker {...field}
                    className={(touched && errors ? `form-control is-invalid` : `form-control`) + (inline ? ` ml-sm-2 mr-sm-2` : ``)}
                    aria-describedby={field.name+'Help'}
                    dateFormat="yyyy-MM-dd"
                />
                {help ? (<small id={field.name+'Help'} className="form-text text-muted">{help}</small>) : null}
                {touched && errors ? <small className="form-text text-danger">{errors}</small> : null}
            </div>
        );
    }
}