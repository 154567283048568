import React, {Fragment} from 'react';
import {Field, getIn, ErrorMessage} from 'formik';
import { doubleDigits, formatTime } from '../utility/functions';

interface LocalProps {
    values : {
        [field : string]: any
    }
    startName : string
    endName : string
    label : string
    inline ?: boolean
}

const TimeRangePickerForm = (props : LocalProps) => {
    const {values, startName, endName, label, inline} = props;
    const intervals = [':00', ':15', ':30', ':45'];

    return (
        <div className={`timerange form-group ${inline ? 'inline-block' : ''}`}>
            {!!label &&
            <label>
                <span className="label-text">{label}</span>
            </label>}
            <div className="">
                <div className="form-element form-inline">
                    <Field component="select" className="form-control" name={startName}>
                        {[...Array(24).keys()].map(h => {
                            const hh = doubleDigits(h);
                            return (
                                <Fragment key={hh}>
                                    {intervals.map(mm => {
                                        const time = hh + mm;
                                        return (
                                            <Fragment key={time}>
                                                <option key={time} value={time} disabled={getIn(values, endName) < time}>
                                                    {formatTime(time)}
                                                </option>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            );
                        })}
                    </Field>
                    &nbsp;-&nbsp;
                    <Field component="select" className="form-control" name={endName}>
                        {[...Array(24).keys()].map(h => {
                            const hh = doubleDigits(h);
                            return (
                                <Fragment key={hh}>
                                    {intervals.map(mm => {
                                        const time = hh + mm;
                                        return (
                                            <Fragment key={time}>
                                                <option key={time} value={time} disabled={getIn(values, startName) > time}>
                                                    {formatTime(time)}
                                                </option>
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            );
                        })}
                    </Field>
                </div>
                <ErrorMessage component='div' name={startName}/>
                <ErrorMessage component='div' name={endName}/>
            </div>
        </div>
    );
};

export default React.memo(TimeRangePickerForm);