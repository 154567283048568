const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: ""
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://0a4dlv0guk.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_TMtIvMtBb",
    APP_CLIENT_ID: "52vln1soskt7pu4mshv8l0epas",
    IDENTITY_POOL_ID: "us-east-1:f0a1cfe1-4cd1-4b23-98d6-7a33e4b1dbe0"
  }
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: ""
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dhg1s0w6y6.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_50w4r6DAy",
    APP_CLIENT_ID: "433nios9ag7n13db08q2qd0ip6",
    IDENTITY_POOL_ID: "us-east-1:245d9ed6-29d8-4c13-ae45-1b055e526166"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
