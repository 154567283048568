import { StoreState } from '../types/StoreState';
import { combineReducers } from "redoodle";
import { userReducer } from './UserReducers';
import { resourcesReducer } from './ResourcesReducers';
import { accountsReducer } from './AccountsReducers';
import { templatesReducer } from './TemplatesReducers';
import { eventsReducer } from './EventsReducers';
import { eventFiltersReducer } from './EventFiltersReducers';

export const rootReducer = combineReducers<StoreState>({
    user: userReducer,
    resources: resourcesReducer,
    accounts: accountsReducer,
    templates: templatesReducer,
    events: eventsReducer,
    filters: eventFiltersReducer
});
