import * as React from 'react';

// Component
import { Component, ReactNode } from 'react';
import { Resource } from '../../types/StoreState';
import { Link } from 'react-router-dom';

// Pure Component
interface DispatchProps {}

interface StateProps {
    resource: Resource;
}

type ResourceRowProps = DispatchProps & StateProps;

export class ResourceRow extends Component<ResourceRowProps> {
    public render(): ReactNode {
        const { resource } = this.props;
        return (
            <tr>
                <td className="actions">
                    <Link to={`/resources/edit/${resource.id}`} className="btn btn-primary btn-sm">Edit</Link>
                </td>
                <td>
                    {resource.name}
                </td>
                <td>
                    {resource.type}
                </td>
                <td>
                    {resource.maximum || `--`} / {resource.warning || `--`}
                </td>
                <td>
                    {resource.email ? (<a href="mailto:${resource.email}">{resource.email}</a>) : ``}
                </td>
            </tr>
        );
    }
}
