import { Account, defaultState, ThunkType } from '../types/StoreState';
import { TypedReducer, Reducer } from "redoodle";
import { TypedAction } from "redoodle";

export const UpdateUser = TypedAction.define("user::update")<{
    user: Account;
}>();

export function updateUser(user: Account): ThunkType {
    return dispatch => {
        dispatch(UpdateUser({user: user}));
    }
}

function createReducer(): Reducer<Account> {
    const builder = TypedReducer.builder<Account>();

    builder.withHandler(UpdateUser.TYPE, (state, payload) => {
        return payload.user;
    });

    builder.withDefaultHandler(state => {
        return state !== undefined ? state : defaultState.user;
    });
    
    return builder.build();    
}

export const userReducer = createReducer();
