import React, { PureComponent, ReactNode, Fragment } from "react";
import { Formik, FormikActions, FormikProps, Form, Field, FieldArray, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import { Event, StoreState, Resource, Template, Account, EAccountRoles, EventCancellation } from '../../types/StoreState';
import { addEvent, updateEvent, cancelEvent, deleteEvent } from "../../reducers/EventsReducers";
import { withRouter, match, RouteChildrenProps } from "react-router";
import { connect } from "react-redux";
import { ResourceSelect } from "../interface/ResourceSelect";
import { FormGroupInputText } from "../interface/FormGroupInputText";
import { EventResourceAllocationRow } from "./EventResourceAllocationRow";
import { FormGroupDatePicker } from "../interface/FormGroupDatePicker";
import TimeRangePickerForm from "../interface/TimeRangePickerForm";
import { nearestFutureMinutes } from "../utility/functions";
import moment, { Moment } from 'moment';
import { FormGroupInputSelect } from "../interface/FormGroupInputSelect";
import '../../css/EventForm.css';
import { FormGroupInputTextArea } from "../interface/FormGroupInputTextArea";

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Event name is required'),
    resources: Yup.array()
        .ensure()
        .of(Yup.object().shape({
            quantity: Yup.number()
                .min(0, 'Must be 0 or positive')
        }))
});

interface UrlParams {
    id: string;
    templateId: string;
}

// Pure Component
interface DispatchProps {
    addEvent: (event: Event) => void;
    updateEvent: (event: Event) => void;
    deleteEvent: (event: Event) => void;
    cancelEvent: (cancellation: EventCancellation) => void;
}

interface StateProps extends RouteChildrenProps {
    events: Event[],
    templates: Template[];
    resources: Resource[],
    accounts: Account[],
    user: Account,
    match: match<UrlParams>
}

interface EventFormState {
    event?: Event;
    template?: Template;
    saveOption: string;
}

type EventFormProps = DispatchProps & StateProps;

const emptyResource:Resource = {
    id: '',
    name: '',
    type: ''
};

const getNewEvent = (user:Account, template?:Template):Event => {

    const baseTime: Moment = nearestFutureMinutes(15, moment());
    
    return {
        id: '',
        name: '',
        status: 'pending',
        notify: false,
        book: false,
        soldOut: false,
        notes: '',
        scheduling: {
            date: new Date(),
            reserveStart: baseTime.clone().add(1, 'h').format('HH:mm'),
            reserveEnd: baseTime.clone().add(4, 'h').format('HH:mm'),
            eventStart: baseTime.clone().add(2, 'h').format('HH:mm'),
            eventEnd: baseTime.clone().add(3, 'h').format('HH:mm'),
        },
        owner: {
            id: user.username,
            name: user.name,
            email: user.email
        },
        template: {
            id: template ? template.id : '0',
            name: template ? template.name : 'none'
        },
        resources: template ? template.resources.map(r => {
            return {
                id: r.id,
                name: r.name,
                type: r.type,
                quantity: r.quantity,
                scheduling: {
                    usesEventReserve: true,
                    reserveStart: baseTime.clone().add(1, 'h').format('HH:mm'),
                    reserveEnd: baseTime.clone().add(4, 'h').format('HH:mm'),
                },
                new: true
            }
        }) : [],
        conflicts: []
    }
}

const getDuplicate = (user:Account, event:Event):Event => {
    return {
        id: '',
        name: event.name,
        status: 'pending',
        notify: false,
        book: false,
        soldOut: false,
        notes: event.notes,
        scheduling: { ...event.scheduling, date: new Date() },
        owner: {
            id: user.username,
            name: user.name,
            email: user.email
        },
        template: { ...event.template },
        resources: event.resources.map(r => {
            return { ...r, new: true }
        }),
        conflicts: []
    }
}

export class PureEventForm extends PureComponent<EventFormProps, EventFormState> {
    constructor(props: any) {
        super(props);
        const { match: { params: { id, templateId }, path }, user, events, templates } = this.props;

        let event = events.find(e => e.id === id);
        if (event && path.includes('/duplicate/')) event = getDuplicate(user, event);

        if (event) {
            if (typeof(event.scheduling.date) === 'string' && event.scheduling.date.length === 10) {
                event = { ...event, scheduling: { ...event.scheduling, date: moment(event.scheduling.date).toDate()}};
            }
        }
        this.state = {
            event: event,
            template: templates.find(t => t.id === templateId),
            saveOption: 'book'
        };
    }

    updateSaveOption = (event:React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({saveOption: event.target.value});
    }

    public render(): ReactNode {

        // tslint:disable-next-line: no-shadowed-variable
        const { match: { params: { id } }, user, accounts, resources, addEvent, updateEvent, cancelEvent, deleteEvent, history } = this.props;
        const { event, template, saveOption } = this.state;
        const newEvent = getNewEvent(user, template);
        const templateName = event ? (event.template.id === '0' ? `` : event.template.name) : (template ? template.name : ``);
        const templateBadge = (templateName === `` ? `` : <span className={`badge template`}>{templateName}</span>)
        const statusBadge = event ? (<span className={`badge `+event.status}>{event.status}</span>) : (<span className={`badge pending`}>New</span>);

        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">{(event && event.id) ? `Edit` : `Add`} Event {statusBadge} {templateBadge}</h3>
                    </div>

                    <div className="panel-content">
                        <Formik
                        
                        initialValues={event || newEvent}
                        validationSchema={FormSchema}
                        onSubmit={async (values: Event, actions: FormikActions<Event>) => {
                            const newStatus = saveOption === 'book' ? 'booked' : 'pending';
                            switch (saveOption) {
                                case 'cancel': {
                                    const cancellation = {
                                        id: values.id,
                                        name: values.name,
                                        notify: values.notify
                                    }

                                    const result = await cancelEvent(cancellation);
                                    break;
                                }

                                default: {
                                    const owner = accounts.find(a => a.username === values.owner.id);
                                    const v = {
                                        ...values,
                                        status: newStatus,
                                        notes: values.notes || undefined,
                                        scheduling: {
                                            ...values.scheduling,
                                            date: typeof(values.scheduling.date) === 'string' ?
                                                values.scheduling.date.substring(0,10) :
                                                moment(values.scheduling.date).format('YYYY-MM-DD')
                                        },
                                        owner: {
                                            ...values.owner,
                                            name: owner!.name,
                                            email: owner!.email
                                        },
                                        book: saveOption === 'book',
                                        notify: !!((!event && saveOption === 'book') || (event && event.id && values.notify)) 
                                    };
                                    
                                    if (saveOption === 'delete') {
                                        const result = await deleteEvent(v);
                                    } else {
                                        if (event && event.id) {
                                            const result = await updateEvent(v);
                                        } else {
                                            const result = await addEvent(v);
                                        }
                                    }
        
                                    break;
                                }
                            }

                            actions.setSubmitting(false);
                            history.goBack();
                        }}
                        render={(props: FormikProps<Event>) => {
                            
                            const filteredResources = resources.filter((resource) => props.values.resources.map((r) => r.id).indexOf(resource.id) === -1);

                            return (
                            <Form>
                                <Field component={FormGroupInputText}
                                    name="name"
                                    label="Event Name"
                                    touched={props.touched.name}
                                    errors={props.errors.name}
                                    help={`A descriptive name, like "Rotunda Garden Party" or "Swanky Soiree"`}
                                />
                                <Field component={FormGroupInputSelect}
                                    name="owner.id"
                                    label="Owner"
                                    touched={props.touched.owner}
                                    errors={props.errors.owner}
                                    options={accounts.filter(a => (a.role !== EAccountRoles.viewer || props.initialValues.owner.id === a.username)).map(a => { return {value: a.username, label: a.name}})}
                                />
                                <div className="row">
                                    <div className="col-md">
                                        <Field component={FormGroupDatePicker}
                                            name="scheduling.date"
                                            label="Event Date"
                                            touched={props.touched.scheduling && props.touched.scheduling!.date}
                                            errors={props.errors.scheduling ? props.errors.scheduling!.date : undefined}
                                        />
                                    </div>

                                    <div className="col-md">
                                        <TimeRangePickerForm
                                            startName="scheduling.eventStart"
                                            endName="scheduling.eventEnd"
                                            label="Event Time"
                                            values={props.values}
                                        />
                                    </div>

                                    <div className="col-md">
                                        <TimeRangePickerForm
                                            startName="scheduling.reserveStart"
                                            endName="scheduling.reserveEnd"
                                            label="Default Reservation"
                                            values={props.values}
                                        />
                                    </div>
                                </div>
                                <Field component={FormGroupInputTextArea}
                                    name="notes"
                                    label="Notes"
                                    touched={props.touched.notes}
                                    errors={props.errors.notes}
                                />
                                <div className="label-text">Resource Allocations</div>
                                <FieldArray
                                    name="resources"
                                    render={(helpers) => (
                                        <Fragment>
                                            <div className="form-group">
                                                <table className="table">
                                                    <thead><tr><td colSpan={5}>
                                                        {props.values.resources.length ?
                                                            <small className="form-text text-muted">Set quantity to zero to remove a resource</small>
                                                            : ''}
                                                    </td></tr></thead>
                                                    <tbody>
                                                    <tr>
                                                        <th className="quantity-column">Quantity</th>
                                                        <th>Resource (type)</th>
                                                        <th>Scheduling</th>
                                                        <th>Max</th>
                                                        <th>Contact Email</th>
                                                    </tr>
                                                    {props.values.resources.length ? props.values.resources.map((resource, index) => {
                                                        return (
                                                            <EventResourceAllocationRow
                                                                key={index}
                                                                index={index}
                                                                resource={resource}
                                                                r={resources.find(r => r.id === resource.id) || emptyResource}
                                                                form={props}
                                                                conflicts={props.values.conflicts.find(c => c.resource.id === resource.id)}
                                                            />
                                                        );
                                                    }) : <tr><td colSpan={4}>No resources have been allocated</td></tr>}
                                                </tbody></table>
                                            </div>
                                            <div className="form-group add-form form-inline">
                                                <Field component={ResourceSelect}
                                                        resources={filteredResources}
                                                        clickHandler={(rid: string) => {
                                                            const resource = resources.find(r => r.id === rid) || emptyResource;
                                                            if (resource) {
                                                                helpers.push({ ...resource,
                                                                    quantity: 1, new: true,
                                                                    scheduling: {
                                                                        usesEventReserve: true,
                                                                        reserveStart: props.values.scheduling.reserveStart,
                                                                        reserveEnd: props.values.scheduling.reserveEnd
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                        key={filteredResources.length}
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                />

                                <div className="form-group form-inline">
                                    <button type="submit" className="btn btn-primary">{saveOption}</button>
                                    <select value={saveOption} onChange={this.updateSaveOption} className="form-control ml-2">
                                        <option value="book">Save and book</option>
                                        <option value="save">Save as pending</option>
                                        { event && (event.id !== '') ? (<>
                                            { event.status !== 'cancelled' ? (<option value="cancel">Cancel event (only name change)</option>) : `` }
                                            <option value="delete">Delete event (cannot be undone)</option>
                                        </>) : `` }
                                    </select>
                                </div>
                                <div className="form-group form-inline">
                                    {(event && event.id && props.initialValues.status === 'booked') ?
                                        (<><Field component="input" name="notify" type="checkbox" className="form-control ml-2" /> <span>Send notifications</span></>)
                                            : ``}
                                </div>
                            </Form>
                        );}} />
                    </div>
                </div>                
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ events, resources, templates, user, accounts }: StoreState) => {
    return { events, resources, templates, user, accounts };
};

const mapDispatchToProps = {
    addEvent,
    updateEvent,
    cancelEvent,
    deleteEvent
};

export const EventForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureEventForm) as any);
