import React, { PureComponent, ReactNode, Fragment } from "react";
import {Event, EventResourceAllocation, StoreState, Account, EAccountRoles} from '../../types/StoreState';
import { formatTime } from "../utility/functions";
import { connect } from "react-redux";
import { EventsConflictTable } from "./EventConflictsTable";
import '../../css/EventPopup.css';

interface StoreProps {
    user: Account;
}

interface LocalProps {
    event: Event;
    close: () => void;
    edit: (id: string) => void;
    duplicate: (id: string) => void;
}

interface LocalState { }

type EventPopupProps = StoreProps & LocalProps;

class UnconnectedEventPopup extends PureComponent<EventPopupProps, LocalState> {

    public render(): ReactNode {
        
        const { event, close, edit, duplicate, user } = this.props;

        const eStart = formatTime(event.scheduling.eventStart);
        const eEnd = formatTime(event.scheduling.eventEnd);
        const rStart = formatTime(event.scheduling.reserveStart);
        const rEnd = formatTime(event.scheduling.reserveEnd);
        
        const isOwner:boolean = user.role === EAccountRoles.admin || user.username === event.owner.id;
        const mayAddEvent:boolean = (user.role !== EAccountRoles.viewer);

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">{event.name}
                        <span className={`badge `+event.status}>{event.status}</span>
                        { (event.template.id === '0') ? `` : (<span className={`badge template`}>{event.template.name}</span>) }
                    </h5>
                    <button type="button" className="close" onClick={close}>×</button>
                </div>
                <div className="modal-body">
                    <div className="scheduling">
                        <div className="schedule contact"><span>Contact:</span>{ event.owner.name }, <a href={`mailto:`+event.owner.email}>{event.owner.email}</a></div>
                        <div className="schedule date"><span>Date of Event:</span>{event.scheduling.date}</div>
                        <div className="schedule event"><span>Schedule:</span>{eStart}-{eEnd}</div>
                        <div className="schedule reservation"><span>Default Reservation:</span>{rStart}-{rEnd}</div>
                    </div>
                    {event!.notes && event.notes.length > 0 ? <div className="notes">{event.notes}</div> : `` }
                    <h4>Resources</h4>
                    <div className="resources">
                        { event.resources.length === 0 ? `No resources are reserved for this event` :
                            (<>
                            <table className="table"><tbody>
                                <tr>
                                    <th className="quantity">Quantity</th>
                                    <th>Resource (type)</th>
                                    <th>Scheduling</th>
                                </tr>
                            { event.resources.map((r: EventResourceAllocation) => {
                                const raStart = formatTime(r.scheduling.reserveStart);
                                const raEnd = formatTime(r.scheduling.reserveEnd);
                                const conflicts = event.conflicts.find((c) => c.resource.id === r.id);
                                
                                return (<Fragment key={r.id}><tr>
                                    <td className="quantity">{r.quantity}</td>
                                    <td>{r.name} ({r.type}){conflicts ? (<span className={`badge conflict`}>conflict</span>) : ``}</td>
                                    <td>{r.scheduling.usesEventReserve ? rStart+`-`+rEnd+` (default)`: raStart+`-`+raEnd}</td>
                                </tr>
                                {conflicts && (
                                    <tr className="conflicts">
                                        <td>&nbsp;</td><td colSpan={2}>
                                            <EventsConflictTable conflicts={conflicts} />
                                        </td>
                                    </tr>
                                )}
                                </Fragment>)
                            })}
                            </tbody></table></>)}
                    </div>
                </div>
                <div className="modal-footer">
                    {mayAddEvent ? (<button type="button" className="btn btn-default mr-auto" onClick={() => duplicate(event.id)}>Duplicate</button>) : null}
                    <button type="button" className="btn btn-default" onClick={close}>Close</button>
                    {isOwner ? (<button type="button" className="btn btn-primary" onClick={() => edit(event.id)}>Edit</button>) : null}
                </div>
            </>
        );
    }
}

// Connector
const mapStateToProps = ({user} : StoreState) => {
    return {user: user};
};

const mapDispatchToProps = {};

export const EventPopup = connect(mapStateToProps, mapDispatchToProps)(UnconnectedEventPopup);
