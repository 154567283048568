import * as React from 'react';

// Component
import {PureComponent, ReactNode} from 'react';

// Connector
import {connect} from 'react-redux';

// Pure Component
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { refreshResources } from '../../reducers/ResourcesReducers';
import { refreshAccounts } from '../../reducers/AccountsReducers';
import { refreshTemplates } from '../../reducers/TemplatesReducers';

import '../../css/Navigation.css';
import { StoreState, Account, EAccountRoles } from '../../types/StoreState';
import { RouteChildrenProps } from 'react-router';

interface StateProps extends RouteChildrenProps {
    user: Account;
}

interface LocalState {
    isLoading: boolean;
}
interface DispatchProps {
    refreshResources: () => void;
    refreshAccounts: () => void;
    refreshTemplates: () => void;
}

type NavigationProps = StateProps & DispatchProps;

export class PureNavigation extends PureComponent<NavigationProps, LocalState> {

    constructor(props:NavigationProps) {
        super(props);
        this.state = {
            isLoading:false
        }
    }

    refresh = async () => {
        this.setState({ isLoading: true });
        await Promise.all([
            this.props.refreshResources(),
            this.props.refreshAccounts(),
            this.props.refreshTemplates()
        ]);
        this.setState({ isLoading: false });
    }

    public render() : ReactNode {
        const { user, history } = this.props;

        return (
            <React.Fragment>
                    {/*  Navbar Start  */}
                    <header className="navbar navbar-fixed">
                        {/*  Navbar Header Start  */}
                        <div className="navbar--header">
                            {/*  Logo Start  */}
                            <Link to="/" className="logo branding toggles">
                                <img src="/assets/img/logo.png" alt="Schedule Fusion" />
                            </Link>
                            {/*  Logo End  */}
            
                            {/*  Sidebar Toggle Button Start  */}
                            <a href="#" className="navbar--btn" data-toggle="sidebar" title="Toggle Sidebar">
                                <i className="fa fa-bars"></i>
                            </a>
                            {/*  Sidebar Toggle Button End  */}
                        </div>
                        {/*  Navbar Header End  */}
            
                        {/*  Sidebar Toggle Button Start  */}
                        <a href="#" className="navbar--btn" data-toggle="sidebar" title="Toggle Sidebar">
                            <i className="fa fa-bars"></i>
                        </a>
                        {/*  Sidebar Toggle Button End  */}
           
                        <div className="navbar--nav ml-3">
                            {/*  Page Title Start  */}
                            <h2 className="page--title h4">The Society of the Four Arts</h2>
                            {/*  Page Title End  */}
                        </div>
                    </header>
                    {/*  Navbar End  */}
            
                    {/*  Sidebar Start  */}
                    <aside className="sidebar" data-trigger="scrollbar">

                        {/*  Sidebar Navigation Start  */}
                        <div className="sidebar--nav">
                            <ul>
                                <li className="is-dropdown">
                                    <a href="#">Navigation</a>
                                    <ul>
                                        <li>
                                            <Link to="/" className="toggles">
                                                <i className="fa fa-calendar-alt"></i>
                                                <span>Calendar</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/events" className="toggles">
                                                <i className="fa fa-ticket-alt"></i>
                                                <span>Event List</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="#" className={`toggles`} onClick={(e) => { e.preventDefault(); this.refresh()}} title="Refreshs resource, template, and user lists, if you must">
                                                <i className={`fa fa-sync-alt`+(this.state.isLoading ? ` spin`: ``)}></i>
                                                <span>Refresh Lookups</span>
                                            </a>
                                        </li>
                                        <li>
                                        <Link to="/logout" className="toggles" onClick={async (e) => { e.preventDefault(); await Auth.signOut(); history.push('/'); }}>
                                                <i className="fa fa-sign-out-alt"></i>
                                                <span>Logout</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                { user && user.role === EAccountRoles.admin && (<>
                                    <li className="is-dropdown">
                                        <a href="#">Administration</a>
                
                                        <ul>
                                            <li>
                                                <Link to="/templates" className="toggles">
                                                    <i className="fas fa-map"></i>
                                                    <span>Programs</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/resources" className="toggles">
                                                    <i className="fa fa-cubes"></i>
                                                    <span>Resources</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/users" className="toggles">
                                                    <i className="fa fa-users"></i>
                                                    <span>Users</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </>)}
                            </ul>
                        </div>
                        {/*  Sidebar Navigation End  */}
                    </aside>
                    {/*  Sidebar End  */}
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ user }: StoreState) => {
    return { user: user };
};

const mapDispatchToProps = {
    refreshResources,
    refreshAccounts,
    refreshTemplates
};

export const Navigation = connect(mapStateToProps, mapDispatchToProps)(PureNavigation);
