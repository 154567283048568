import * as React from 'react';

// Component
import { PureComponent, ReactNode, Fragment } from 'react';

// Connector
import { connect } from 'react-redux';
import { Template, StoreState } from '../../types/StoreState';
import { refreshTemplates, templatesReducer } from '../../reducers/TemplatesReducers';
import { TemplateRow } from './TemplateRow';
import { withRouter, RouterProps } from 'react-router';
import { Link } from 'react-router-dom';

// Pure Component
interface DispatchProps {
    refreshTemplates: () => void;
}

interface StateProps extends RouterProps {
    templates: Template[]
}

type TemplateTableProps = DispatchProps & StateProps;

export class PureTemplateTable extends PureComponent<TemplateTableProps> {

    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        const { refreshTemplates } = this.props;
        refreshTemplates();
    }

    public render(): ReactNode {

        const { templates } = this.props;

        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Programs
                            <Link to="/templates/add" className="float-right btn btn-primary btn-sm">Add</Link>
                        </h3>
                    </div>

                    <div className="panel-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="actions">Actions</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates.map(template => <TemplateRow key={template.id} template={template} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ templates }: StoreState) => {
    return { templates: templates };
};

const mapDispatchToProps = {
    refreshTemplates
};

export const TemplateTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureTemplateTable) as any);
