import React, { PureComponent, ReactNode } from "react";
import { Formik, FormikActions, FormikProps, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import { Resource, StoreState } from '../../types/StoreState';
import { refreshResources, addResource, updateResource } from "../../reducers/ResourcesReducers";
import { withRouter, match, RouteChildrenProps } from "react-router";
import { connect } from "react-redux";
import { ResultMessage } from "../../types/Messaging";

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Resource name is required'),
    type: Yup.string()
        .required('Resource type is required')
        .min(3, 'Type must be at least 3 characters long'),
    email: Yup.string()
        .required('Resource contact email is required')
        .email('Not a valid email address')
});

interface UrlParams {
    id: string;
}

// Pure Component
interface DispatchProps {
    refreshResources: () => ResultMessage;
    addResource: (resource: Resource) => void;
    updateResource: (resource: Resource) => void;
}

interface StateProps extends RouteChildrenProps {
    resources: Resource[],
    match: match<UrlParams>
}

interface ResourceFormState {
    resource?: Resource
}

type ResourceFormProps = DispatchProps & StateProps;

export class PureResourceForm extends PureComponent<ResourceFormProps, ResourceFormState> {
    constructor(props: any) {
        super(props);

        const { match: { params: { id } }, resources } = this.props;
        this.state = {
            resource: resources.find(r => r.id === id)
        };
    }

    public render(): ReactNode {

        const { match: { params: { id } }, resources, addResource, updateResource, history } = this.props;
        const { resource } = this.state;
        const types = [...new Set(resources.map((resource) => resource.type))];
        const initialResource = resource ? {
            ...resource,
            maximum: resource.maximum || 1,
            warning: resource.warning || 1,
            email: resource.email || ''
        } : undefined;
        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">{id ? `Edit` : `Add`} Resource</h3>
                    </div>

                    <div className="panel-content">
                        <Formik
                        enableReinitialize={true}
                        initialValues={initialResource || {
                            id: '',
                            name: '',
                            type: '',
                            email: '',
                            maximum: 1,
                            warning: 1
                        }}
                        validationSchema={FormSchema}
                        onSubmit={async (values: Resource, actions: FormikActions<Resource>) => {
                            if (resource) {
                                const result = await updateResource(values);
                            } else {
                                const result = await addResource(values);
                            }
                            actions.setSubmitting(false);
                            history.push('/resources');
                        }}
                        render={(props: FormikProps<Resource>) => (
                            <Form>
                                <Field
                                    name="name"
                                    render={({ field, form }: FieldProps<Resource>) => (
                                        <div className="form-group">
                                            <label htmlFor="name">Resource Name</label>
                                            <input type="text" {...field} className={props.touched.name && props.errors.name ? `form-control is-invalid` : `form-control`} aria-describedby="resourceNameHelp" />
                                            <small id="resourceNameHelp" className="form-text text-muted">A descriptive name, like "Rotunda Garden" or "Digital Projector"</small>
                                            {props.touched.name && props.errors.name ? <small className="form-text text-danger">{props.errors.name}</small> : null}
                                        </div>
                                    )}
                                />
                                <Field
                                    name="type"
                                    render={({ field, form }: FieldProps<Resource>) => (
                                        <div className="form-group">
                                            <label htmlFor="type">Resource Type</label>
                                            <input type="text" {...field} className={props.touched.type && props.errors.type ? `form-control is-invalid` : `form-control`} aria-describedby="resourcetypeHelp" />
                                            <div className="existing-value-selection"><span>Use existing value:</span> { types.map((type) => <a href="#" key={type} className="badge badge-secondary" onClick={() => props.setValues({...props.values, type: type})}>{type}</a>)}</div>
                                            <small id="resourcetypeHelp" className="form-text text-muted">A simple lowercase type "tag" works best, like "location" or "equipment"</small>
                                            {props.touched.type && props.errors.type ? <small className="form-text text-danger">{props.errors.type}</small> : null}
                                        </div>
                                    )}
                                />
                                <Field
                                    name="email"
                                    render={({ field, form }: FieldProps<Resource>) => (
                                        <div className="form-group">
                                            <label htmlFor="email">Contact Email</label>
                                            <input type="text" {...field} className={props.touched.email && props.errors.email ? `form-control is-invalid` : `form-control`} aria-describedby="resourceemailHelp" />
                                            {props.touched.email && props.errors.email ? <small className="form-text text-danger">{props.errors.email}</small> : null}
                                        </div>
                                    )}
                                />
                                <div className="row">
                                    <div className="col-sm">
                                        <Field
                                            name="maximum"
                                            render={({ field, form }: FieldProps<Resource>) => (
                                                <div className="form-group">
                                                    <label htmlFor="maximum">Maximum Available</label>
                                                    <input type="number" {...field} className={props.touched.maximum && props.errors.maximum ? `form-control is-invalid` : `form-control`} aria-describedby="resourcemaximumHelp" />
                                                    {props.touched.maximum && props.errors.maximum ? <small className="form-text text-danger">{props.errors.maximum}</small> : null}
                                                    <small id="resourcemaximumHelp" className="form-text text-muted">Attempting to book an event that exceeds this amount in any time period will leave the event as "conflict"</small>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <Field
                                            name="warning"
                                            render={({ field, form }: FieldProps<Resource>) => (
                                                <div className="form-group">
                                                    <label htmlFor="warning">Email Warning At</label>
                                                    <input type="number" {...field} className={props.touched.warning && props.errors.warning ? `form-control is-invalid` : `form-control`} aria-describedby="resourcewarningHelp" />
                                                    {props.touched.warning && props.errors.warning ? <small className="form-text text-danger">{props.errors.warning}</small> : null}
                                                    <small id="resourcewarningHelp" className="form-text text-muted">When a booking creates a time period with this many or more booked, an email will be sent to the contact</small>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </Form>
                        )} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ resources }: StoreState) => {
    return { resources: resources };
};

const mapDispatchToProps = {
    refreshResources,
    addResource,
    updateResource
};

export const ResourceForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureResourceForm) as any);
