import React, { PureComponent, ReactNode } from "react";
import {Event} from '../../types/StoreState';
import { formatTime } from "../utility/functions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons";

interface EventCalendarDayProps {
    dayNumber: string,
    events: Event[],
    filteredEvents: Event[],
    onEventClick: (event: Event) => void,
    onResourcesClick: (events: Event[]) => void
}

interface EventCalendarDayState { }

export class EventCalendarDay extends PureComponent<EventCalendarDayProps, EventCalendarDayState> {

    public render(): ReactNode {
        
        const { dayNumber, events, filteredEvents, onEventClick, onResourcesClick } = this.props;

        filteredEvents.sort((a, b) => {
            if (a.scheduling.eventStart === b.scheduling.eventStart) return 0;
            return ((a.scheduling.eventStart < b.scheduling.eventStart) ? -1 : 1);
        });

        return (
            <div className="day">
                { (events.length > 0) ? <span className="badge badge-xs badge-secondary float-right resource-report-button" onClick={() => onResourcesClick(events)}><FontAwesomeIcon icon={faCubes} size="xs" title="Resource usage" /></span> : `` }
                <div className="daynumber">
                    {dayNumber}
                </div>
                { filteredEvents.map(e => {
                    
                    const start = formatTime(e.scheduling.eventStart);
                    const end = formatTime(e.scheduling.eventEnd);
                    
                    return (
                    <div className={`event `+e.status} key={e.id} onClick={() => onEventClick(e)}>
                        <div className="event-time">{start}-{end}</div>
                        <div className="event-title">{e.name}</div>
                    </div>
                ) }) }
            </div>
        );
    }
}
