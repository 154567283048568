import React, { PureComponent, ReactNode } from "react";
import { Field, FormikProps } from "formik";
import TimeRangePickerForm from '../interface/TimeRangePickerForm';
import { EventResourceAllocation, Resource, Event, ResourceConflicts } from "../../types/StoreState";
import '../../css/EventResourceAllocationRow.css';
import { EventsConflictTable } from "./EventConflictsTable";

interface LocalProps {
    resource: EventResourceAllocation;
    r: Resource;
    index: number;
    form: FormikProps<Event>;
    conflicts?: ResourceConflicts;
}

export class EventResourceAllocationRow extends PureComponent<LocalProps> {
    private resetReservationTimes = () => {
        const { index, form: { setFieldValue, values } } = this.props;
        setFieldValue(`resources.${index}.scheduling.reserveStart`, values.scheduling.reserveStart);
        setFieldValue(`resources.${index}.scheduling.reserveEnd`, values.scheduling.reserveEnd);
    }
    
    public render(): ReactNode {

        const { resource, r, conflicts, index, form: { values, handleChange } } = this.props;
        const usesEventReserve: boolean = resource.scheduling.usesEventReserve;

        return (<>
            <tr>
                <td className="quantity-column">
                    <Field
                        name={`resources.${index}.quantity`}
                        component="input"
                        className="form-control"
                        min="0"
                        max={r.maximum ? r.maximum : null}
                        type="number"
                        onChange={(e: React.ChangeEvent) => { if(r.maximum && parseInt(e.target.nodeValue ? e.target.nodeValue : '0') > r.maximum) e.target.nodeValue = (r.maximum ? r.maximum.toString() : e.target.nodeValue); handleChange(e); }}
                    />
                </td>
                <td>{resource.name} ({resource.type}){conflicts && <span className={`badge conflict`}>conflict</span>}</td>
                <td>
                    <Field
                        name={`resources.${index}.scheduling.usesEventReserve`}
                        component="input"
                        type="checkbox"
                        checked={usesEventReserve}
                        onChange={(e: React.ChangeEvent) => { this.resetReservationTimes(); handleChange(e); }}
                    /><span>Use Default Reservation</span><br />
                    {!usesEventReserve && (
                        <TimeRangePickerForm
                            startName={`resources.${index}.scheduling.reserveStart`}
                            endName={`resources.${index}.scheduling.reserveEnd`}
                            label=""
                            values={values}
                        />
                    )}
                </td>
                <td>{r.maximum ? r.maximum : '--'}</td>
                <td>{r.email ? (<a href={'mailto:'+r.email}>{r.email}</a>) : '--'}</td>
            </tr>
            {conflicts &&
                <tr className="conflicts">
                    <td>&nbsp;</td>
                    <td colSpan={4}>
                        <EventsConflictTable conflicts={conflicts} />
                    </td>
                </tr>
            }
        </>);
    }
}