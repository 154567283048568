import { Template, defaultState, ThunkType } from '../types/StoreState';
import { TypedReducer, TypedAction, Reducer } from "redoodle";
import { API } from "aws-amplify";

export const RefreshTemplates = TypedAction.define("templates::refresh")<{
    templates: Template[];
}>();

export function refreshTemplates(): ThunkType {
    return async dispatch => {
        let response = {
            success: true,
            error: undefined
        }
 
        try {
            const templates = await API.get('api', '/templates/', {});
            dispatch(RefreshTemplates({ templates: templates }));
        } catch (e) {
            response = {
                success: false,
                error: e.message
            };
        }

        return response;
    }
}

export function addTemplate(template: Template): ThunkType {
    return async dispatch => {
        try {
            const result = await API.post('api', '/templates/', { body: template });
            refreshTemplates();
        } catch (e) {
            alert(e.message);
        }
    }
}

export function updateTemplate(template: Template): ThunkType {
    return async dispatch => {
        try {
            const result = await API.put('api', '/templates/', { body: template });
            refreshTemplates();
        } catch (e) {
            alert(e.message);
        }
    }
}

export function getTemplate(id: string): ThunkType {
    return async dispatch => {
        let response = {
            success: false,
            data: undefined,
            error: undefined
        }

        try {
            const result = await API.get('api', '/templates/'+id, {});
            response = {
                success: true,
                data: result,
                error: undefined
            }
        } catch (e) {
            response = {
                success: false,
                data: undefined,
                error: e.message
            }
        }

        return response;
    }
}

function createReducer(): Reducer<Template[]> {
    const builder = TypedReducer.builder<Template[]>();

    builder.withHandler(RefreshTemplates.TYPE, (state, payload) => {
        return payload.templates;
    });

    builder.withDefaultHandler(state => {
        return state !== undefined ? state : defaultState.templates;
    });
    
    return builder.build();
}

export const templatesReducer = createReducer();
