import React from 'react';
import { statusOptions } from '../../types/StoreState';
import '../../css/EventStatusKey.css';

export const EventStatusKey = () => (
    <div className="calendar-key">
        {statusOptions.map(o => (
            <span key={o.value} className={o.value}>{o.label}</span>
        ))}
    </div>
);
