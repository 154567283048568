import * as React from 'react';
import { Account } from '../../types/StoreState';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { API } from 'aws-amplify';

type AccountRowProps = {
    account: Account;
}

export const AccountRow = (props: AccountRowProps) => {
    const { account } = props;
    const unconfirmed = account.status === 'FORCE_CHANGE_PASSWORD';

    const [isReinviting, setReinviting] = useState<boolean>(false);
    const [didReinvite, setDidReinvite] = useState<boolean>(false);
    const [inviteError, setInviteError] = useState<string>('');

    const sendReset = () => {
        (async () => {
            try {
                setReinviting(true);
                setInviteError('');
                await API.post('api', '/users/', { body: { ...account, resend: true } });
                setDidReinvite(true);
                setReinviting(false);
            } catch (e) {
                setInviteError('Invitation failed to send');
                setDidReinvite(true);
                setReinviting(false);
            }
        })();
    }

    return (
        <tr>
            <td className="actions">
                <Link to={`/users/edit/${account.username}`} className="btn btn-primary btn-sm">Edit</Link>
                {unconfirmed ? <button onClick={() => sendReset()} className="btn btn-primary btn-sm" disabled={isReinviting}>Re-invite</button> : ''}
            </td>
            <td>
                {account.name}
                {unconfirmed ? <span className="badge badge-danger">unconfirmed</span> : ''}
                {isReinviting ?
                    <><br /><small>Sending invitation...</small></>
                    : (didReinvite ? <><br /><small>{inviteError.length > 0 ? inviteError : 'Sent new invitation'}</small></> : '')}
            </td>
            <td>
                {account.role}
            </td>
            <td>
                {account.email}
            </td>
        </tr>
    );
}
