import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './configureStore';
import { Provider } from 'react-redux';
// import { AppWithAuth } from './AppWithAuth';

const store = configureStore();
const map = (message:string) => {
    if (/username cannot be empty/i.test(message)) {
      return 'Email is required';
    }

    if (/generate.*allenge/i.test(message)) {
      return 'Password is required';
    }

    if (/incorrect.username.or.password/i.test(message)) {
      return 'Incorrect email or password';
    }

    return message;
}

render(
    <Provider store={store}>
        <App errorMessage={map} />
    </Provider>, document.getElementById('root')
);

serviceWorker.unregister();
