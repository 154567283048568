import React, { PureComponent, ReactNode } from "react";
import { FieldProps } from "formik";
import { FormObject, IBasicOption } from "../../types/StoreState";

interface LocalProps {
    name: string;
    label: string;
    help?: string;
    touched?: boolean;
    errors?: string;
    options: IBasicOption[];
}

type OwnProps = FieldProps<FormObject> & LocalProps;

export class FormGroupInputSelect extends PureComponent<OwnProps> {
    public render(): ReactNode {

        const { field, label, help, touched, errors, options } = this.props;
        
        return (
            <div className="form-group">
                <label htmlFor={field.name}><span className="label-text">{label}</span></label>
                <select {...field} className={touched && errors ? `form-control is-invalid` : `form-control`} aria-describedby={name+'Help'}>
                    {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                </select>
                {help ? (<small id={field.name+'Help'} className="form-text text-muted">{help}</small>) : null}
                {touched && errors ? <small className="form-text text-danger">{errors}</small> : null}
            </div>
        );
    }
}