import * as React from 'react';

import { PureComponent, ReactNode } from 'react';
import { Resource } from '../../types/StoreState';
import { sortResources } from '../utility/functions';

interface LocalProps {
    clickHandler: (id: string) => void;
    resources: Resource[];
}

interface LocalState {
    resourceOptions: { [k: string]: any },
    types: string[],
    resourceId: string;
}

export class ResourceSelect extends PureComponent<LocalProps, LocalState> {

    constructor(props: Readonly<LocalProps>) {
        super(props);
        const { resources } = this.props;
        const resourceOptions = sortResources(resources);
        const types = [...new Set(resources.map((resource) => resource.type))].sort();
        this.state = {
            ...this.state,
            resourceOptions: resourceOptions,
            types: types,
            resourceId: (types.length && resourceOptions[types[0]].length) ? resourceOptions[types[0]][0].id : ''
        };
    }

    private setResourceId(id: string) {
        this.setState({
            ...this.state,
            resourceId: id
        });
    }

    public render(): ReactNode {

        const { clickHandler } = this.props;

        return (
            <div className="ml-auto">
                <select className="form-control mr-2" onChange={e => this.setResourceId(e.target.value)}>
                    {this.state.types.map((type) => {
                        return (
                            <optgroup key={type} label={type}>
                                {this.state.resourceOptions[type].map((ro: { id: string; name: string; }) => (
                                    <option key={ro.id} value={ro.id}>{ro.name}</option>
                                ))}
                            </optgroup>);
                    })}
                </select>
                <button type="button" className="btn btn-primary btn-sm"
                    onClick={() => clickHandler(this.state.resourceId)}>Add</button>
            </div>
        )
    }
}
