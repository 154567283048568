/*
const Container = {};
const FormContainer = {};
const FormSection = {};
const FormField = {};
const SectionHeader = {};
const SectionBody = {};
const SectionFooter = {};
const SectionFooterPrimaryContent = {};
const SectionFooterSecondaryContent = {};
const Input = {};
const Button = {};
const PhotoPickerButton = {};
const PhotoPlaceholder = {};
const SignInButton = {};
const SignInButtonIcon = {};
const SignInButtonContent = {};
const Strike = {};
const StrikeContent = {};
const ActionRow = {};
const FormRow = {};
const A = {};
const Hint = {};
const Radio = {};
const Label = {};
const InputLabel = {};
const AmazonSignInButton = {};
const FacebookSignInButton = {};
const GoogleSignInButton = {};
const OAuthSignInButton = {};
const Toast = {};
const NavBar = {};
const NavRight = {};
const Nav = {};
const NavItem = {};
const NavButton = {};

const themeOptions = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,

  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  label: Label,
  inputLabel: InputLabel,
  toast: Toast,
  
  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton
};
*/

import Logo from './images/logo.png';
import Background from './images/wrapper-bg.svg';

export const theme = {
    button: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    formContainer: {
        margin: '0',
        overflowY: 'auto',
    },
    formSection: {
        borderRadius: '0',
        backgroundColor: '#443642',
        marginBottom: '0',
    },
    sectionHeader: { 
        color: '#fff',
        before: { 
            content: '', 
            backgroundImage: 'url('+Logo+')',
            height:'80px', 
            width:'70%', 
            display: 'block', 
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            maxWidth: '70vw',
            margin: '0 auto 2rem'
        },
    },
    container: {
        position:'absolute', 
        width:'100vw', 
        height:'100%',
        minHeight: '100vh',
        display:'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        backgroundImage: 'url('+Background+')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    inputLabel: {
        color: '#fff',
    },
    hint: {
        color: '#aaa',
    },
    sectionFooterSecondaryContent: {
        color: '#aaa',
    },
    a: {
        color: '#2bb3c0',
    }
}
