import * as React from 'react';

// Component
import { PureComponent, ReactNode, Fragment } from 'react';

// Connector
import { connect } from 'react-redux';
import { Resource, StoreState } from '../../types/StoreState';
import { refreshResources } from '../../reducers/ResourcesReducers';
import { ResourceRow } from './ResourceRow';
import { withRouter, RouterProps } from 'react-router';
import { Link } from 'react-router-dom';

// Pure Component
interface DispatchProps {
    refreshResources: () => void;
}

interface StateProps extends RouterProps {
    resources: Resource[]
}

type ResourceTableProps = DispatchProps & StateProps;

export class PureResourceTable extends PureComponent<ResourceTableProps> {

    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        const { refreshResources } = this.props;
        refreshResources();
    }

    public render(): ReactNode {

        const { resources } = this.props;

        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Resources
                            <Link to="/resources/add" className="float-right btn btn-primary btn-sm">Add</Link>
                        </h3>
                    </div>

                    <div className="panel-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="actions">Actions</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Max/Warning</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resources.map(resource => <ResourceRow key={resource.id} resource={resource} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ resources }: StoreState) => {
    return { resources: resources };
};

const mapDispatchToProps = {
    refreshResources
};

export const ResourceTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureResourceTable) as any);
