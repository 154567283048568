import * as React from 'react';

// Component
import { Component, ReactNode } from 'react';
import { Template } from '../../types/StoreState';
import { Link } from 'react-router-dom';

// Pure Component
interface DispatchProps {}

interface StateProps {
    template: Template;
}

type TemplateRowProps = DispatchProps & StateProps;

export class TemplateRow extends Component<TemplateRowProps> {
    public render(): ReactNode {
        const { template } = this.props;
        return (
            <tr>
                <td className="actions">
                    <Link to={`/templates/edit/${template.id}`} className="btn btn-primary btn-sm">Edit</Link>
                </td>
                <td>
                    {template.name}
                </td>
            </tr>
        );
    }
}
