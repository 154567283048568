import { Moment } from 'moment';
import { Resource, IGroupedOptions, Event, EventFilters } from '../../types/StoreState';

export const doubleDigits = (n: number) => n.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

export const nearestMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const nearestPastMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const nearestFutureMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

/** Credit for nearestMinutes, nearestPastMinutes, & nearestFutureMinutes goes to: https://github.com/moment/moment/issues/959#issuecomment-301767843 */

export function sortResources(resources: Resource[]) {
  const obj: { [k: string]: any } = {};
  const grouped = resources.reduce((acc, r) => {
      if (!acc.hasOwnProperty(r.type)) { acc[r.type] = []; }
      acc[r.type].push(r);
      return acc;
  }, obj);
  
  return grouped;
}

export function getFiltersFromResources(resources:Resource[]): IGroupedOptions {
  const sorted = sortResources(resources);
  const grouped:IGroupedOptions = {};
  for (const prop in sorted) {
    if (sorted.hasOwnProperty(prop)) {
      grouped[prop] = sorted[prop].map((item:Resource) =>({ value: item.id, label: item.name }));
    }
  }
  return grouped;
}

export function formatTime(time: string) {
  const [HH, m]: number[] = time.split(':').map(s => Number(s));
  const h = HH % 12;
  const mm = doubleDigits(m);
  const ampm: 'am' | 'pm' = HH / 12 < 1 ? 'am' : 'pm';
  return `${h || 12}:${mm}${ampm}`;
}

export const applyFilters = (events:Event[], filters:EventFilters) => {

  const statusUnion = filters.status.length === 0 ?
    events.map(e=>e.id) :
    events.filter(e => filters.status.includes(e.status)).map(e=>e.id);

  const resourceUnion = filters.resource.length === 0 ?
    events.map(e=>e.id) :
    events.filter(e => {
      const r = e.resources.map(r=>r.id);
      return filters.resource.filter(f => r.includes(f)).length > 0;
    }).map(e=>e.id);

  const ownerUnion = filters.owner.length === 0 ?
    events.map(e=>e.id) :
    events.filter(e => filters.owner.includes(e.owner.id)).map(e=>e.id);

  const templateUnion = filters.template.length === 0 ?
    events.map(e=>e.id) :
    events.filter(e => filters.template.includes(e.template.id)).map(e=>e.id);

  const eventIntersect = statusUnion.filter(e => resourceUnion.includes(e)).filter(e => ownerUnion.includes(e)).filter(e => templateUnion.includes(e));

  return events.filter(e => eventIntersect.includes(e.id));

}
