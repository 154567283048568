import React, { PureComponent, ReactNode } from "react";
import { FieldProps } from "formik";
import { FormObject } from "../../types/StoreState";

interface LocalProps {
    name: string;
    label: string;
    help?: string;
    touched?: boolean;
    errors?: string;
}

type OwnProps = FieldProps<FormObject> & LocalProps;

export class FormGroupInputText extends PureComponent<OwnProps> {
    public render(): ReactNode {

        const { field, form, name, label, help, touched, errors } = this.props;
        
        return (
            <div className="form-group">
                <label htmlFor={name}><span className="label-text">{label}</span></label>
                <input type="text" {...field} className={touched && errors ? `form-control is-invalid` : `form-control`} aria-describedby={name+'Help'} />
                {help ? (<small id={name+'Help'} className="form-text text-muted">{help}</small>) : null}
                {touched && errors ? <small className="form-text text-danger">{errors}</small> : null}
            </div>
        );
    }
}