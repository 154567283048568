import React, { PureComponent, ReactNode } from "react";
import { Field } from "formik";
import { TemplateResourceAllocation, Resource } from "../../types/StoreState";

interface LocalProps {
    resource: TemplateResourceAllocation;
    r: Resource;
    index: number;
}

export class TemplateResourceAllocationRow extends PureComponent<LocalProps> {
    public render(): ReactNode {

        const { resource, r, index } = this.props;
        
        return (
            <tr>
                <td className="quantity-column">
                    <Field
                        name={`resources.${index}.quantity`}
                        component="input"
                        className="form-control"
                        min="0"
                        type="number"
                    />
                </td>
                <td>{resource.name} ({resource.type})</td>
                <td>{r.maximum ? r.maximum : '--'}</td>
                    <td>{r.email ? (<a href={'mailto:'+r.email}>{r.email}</a>) : '--'}</td>
            </tr>
        );
    }
}