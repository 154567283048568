import * as React from 'react';

// Component
import { PureComponent, ReactNode } from 'react';
import ReactModal from 'react-modal';

// Connector
import { connect } from 'react-redux';
import { Event, StoreState, EAccountRoles, Account, Resource, EventFilters, Template } from '../../types/StoreState';
import { refreshEventsDateRange } from '../../reducers/EventsReducers';
import { EventRow } from './EventRow';
import { withRouter, RouterProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Field, Form, Formik, FormikProps, FormikActions } from 'formik';
import { FormGroupDatePicker } from '../interface/FormGroupDatePicker';
import moment, { Moment } from 'moment';
import EventsFilter from './EventFilter';
import '../../css/DayPopup.css';
import '../../css/EventTable.css';
import { EventPopup } from './EventPopup';
import { applyFilters } from '../utility/functions';
import { EventAdder } from './EventAdder';

// Pure Component
interface DispatchProps {
    refreshEventsDateRange: (start: string, end: string) => {
        success: true,
        error: undefined
    };
}

interface SearchProps {
    start: Moment,
    end: Moment
}

interface StateProps extends RouterProps {
    events: Event[],
    search: SearchProps,
    user: Account,
    resources: Resource[],
    filters: EventFilters,
    accounts: Account[],
    templates: Template[]
}

interface LocalState {
    modalIsOpen: boolean,
    modalEvent?: Event
}

type EventTableProps = DispatchProps & StateProps;

export class PureEventTable extends PureComponent<EventTableProps, LocalState> {

    constructor(props: any) {
        super(props);
        this.state = {
            modalIsOpen: false,
            modalEvent: undefined
        }
    }

    handleOpenModal = (event: Event) => {
        this.setState({ modalIsOpen: true, modalEvent: event });
    }

    handleCloseModal = () => {
        this.setState({ modalIsOpen: false });
    }

    goToEventEdit = (id: string) => {
        this.handleCloseModal();
        this.props.history.push(`/events/edit/` + id);
    }

    duplicateEvent = (id: string) => {
        this.handleCloseModal();
        this.props.history.push(`/events/duplicate/`+id);
    }

    public componentDidMount() {
        const { refreshEventsDateRange } = this.props;
        refreshEventsDateRange(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'));
    }

    public render(): ReactNode {

        const { events, refreshEventsDateRange, user, resources, filters, accounts, templates } = this.props;
        const filteredEvents = applyFilters(events, filters);

        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Event List
                        {user && (user.role !== EAccountRoles.viewer) && <EventAdder />}
                        </h3>
                        <EventsFilter resources={resources} accounts={accounts} templates={templates} />
                    </div>

                    <div className="panel-body">
                        <Formik
                            initialValues={{
                                start: moment().startOf('month'),
                                end: moment().endOf('month')
                            }}
                            onSubmit={async (values: SearchProps, actions: FormikActions<SearchProps>) => {
                                const start = moment(values.start).format('YYYY-MM-DD');
                                const end = moment(values.end).format('YYYY-MM-DD');
                                await refreshEventsDateRange(start, end);
                                actions.setSubmitting(false);
                            }}
                            render={(props: FormikProps<SearchProps>) => (
                            <Form className="form-inline mb-4">
                                <Field component={FormGroupDatePicker}
                                    name="start"
                                    label="From"
                                    touched={undefined}
                                    errors={undefined}
                                    inline={true}
                                />
                                <Field component={FormGroupDatePicker}
                                    name="end"
                                    label="To"
                                    touched={undefined}
                                    errors={undefined}
                                    inline={true}
                                />
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-sm">Refresh</button>
                                </div>
                            </Form>
                            )}
                        />

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="actions">Actions</th>
                                        <th>Name</th>
                                        <th>Schedule</th>
                                        <th>Owner</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredEvents.length > 0 ? filteredEvents.map(event => {
                                        const isOwner:boolean = user.role === EAccountRoles.admin || user.username === event.owner.id;
                                        const mayAddEvent:boolean = (user.role !== EAccountRoles.viewer);

                                        return (<React.Fragment key={event.id}>
                                        <EventRow event={event} onClick={this.handleOpenModal} isOwner={isOwner} mayAddEvent={mayAddEvent} />
                                    </React.Fragment>)}) : (<tr><td colSpan={3}>No events found</td></tr>)}
                                </tbody>
                            </table>
                        </div>
                        <ReactModal
                            isOpen={this.state.modalIsOpen}
                            appElement={(document.getElementById('root'))!}
                            className="day-popup panel"
                            overlayClassName="day-popup-overlay"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={this.handleCloseModal}
                        >
                            {typeof (this.state.modalEvent) === 'undefined' ? 'No event selected' :
                                <EventPopup
                                    event={this.state.modalEvent}
                                    close={this.handleCloseModal}
                                    edit={this.goToEventEdit}
                                    duplicate={this.duplicateEvent}
                                />
                            }
                        </ReactModal>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ events, user, resources, filters, accounts, templates }: StoreState) => {
    return { events: events, user: user, resources:resources, filters: filters, accounts:accounts, templates:templates };
};

const mapDispatchToProps = {
    refreshEventsDateRange
};

export const EventTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureEventTable) as any);
