import { Account, defaultState, ThunkType } from '../types/StoreState';
import { TypedReducer, Reducer } from "redoodle";
import { TypedAction } from "redoodle";
import { API } from 'aws-amplify';

export function refreshAccounts(): ThunkType {
    return async dispatch => {
        let response = {
            success: true,
            error: undefined
        }

        try {
            const accounts = await API.get('api', '/users/', {});
            dispatch(RefreshAccounts({ accounts: accounts }));
        } catch (e) {
            response = {
                success: false,
                error: e.message
            };
        }

        return response;
    }
}

export function addAccount(account: Account): ThunkType {
    return async dispatch => {
        try {
            const result = await API.post('api', '/users/', {body: account});
        } catch (e) {
            alert(e.message);
        }
    }
}

export function updateAccount(account: Account): ThunkType {
    return async dispatch => {
        try {
            const result = await API.put('api', '/users/', {body: account});
        } catch (e) {
            alert(e.message);
        }
    }
}

export const RefreshAccounts = TypedAction.define("accounts::refresh")<{
    accounts: Account[];
}>();

function createReducer(): Reducer<Account[]> {
    const builder = TypedReducer.builder<Account[]>();

    builder.withHandler(RefreshAccounts.TYPE, (state, payload) => {
        return payload.accounts;
    });

    builder.withDefaultHandler(state => {
        return state !== undefined ? state : defaultState.accounts;
    });
    
    return builder.build();    
}

export const accountsReducer = createReducer();
