import React, { Fragment } from 'react';
import { formatTime } from '../utility/functions';
import { ResourceConflicts } from '../../types/StoreState';
import '../../css/EventConflictsTable.css'

interface LocalProps {
    conflicts: ResourceConflicts
}

export const EventsConflictTable = (props:LocalProps) => {

    const conflicts = props.conflicts.conflicts;
    const resourceId = props.conflicts.resource;

    return (
        <table className="table">
            <tbody>
                <tr>
                    <th>Time</th>
                    <th>Max</th>
                    <th>Used</th>
                </tr>
                {conflicts.map((c, index) => {
                    const events = c.events;

                    return (<Fragment key={resourceId+'|conflict|'+index}>
                        <tr className="conflict-times">
                            <td>{formatTime(c.start)}-{formatTime(c.end)}</td>    
                            <td>{c.max}</td>
                            <td>{c.used}</td>
                        </tr>
                        {events.map(e => (
                            <tr key={resourceId+'|conflict|event|'+e.eventId} className="conflict-event">
                                <td colSpan={4}><strong>{e.quantity}x</strong> {e.eventName}, {<a href={`mailto:`+e.event.owner.email}>{e.event.owner.name}</a>}</td>
                            </tr>
                        ))}
                    </Fragment>)
                })}
            </tbody>
        </table>
    )
}