import * as React from 'react';

// Component
import {PureComponent, ReactNode, Fragment} from 'react';
import ReactModal from 'react-modal';

// Connector
import {connect} from 'react-redux';
import {Event, StoreState, EventFilters, Account, EAccountRoles, Resource, Template } from '../../types/StoreState';
import {refreshEventsDateRange} from '../../reducers/EventsReducers';
import {withRouter, RouteChildrenProps} from 'react-router';
import {Link} from 'react-router-dom';
import * as M from 'moment';
import { Moment } from 'moment';
import { extendMoment, DateRange } from 'moment-range';
import { EventCalendarDay } from './EventCalendarDay';
import '../../css/EventCalendar.css';
import '../../css/DayPopup.css';
import { EventPopup } from './EventPopup';
import EventsFilter from './EventFilter';
import { EventStatusKey } from './EventStatusKey';
import { applyFilters } from '../utility/functions';
import { EventAdder } from './EventAdder';
import { EventResourcesPopup } from './EventResourcesPopup';

const moment = extendMoment(M);

// Pure Component
interface DispatchProps {
    refreshEventsDateRange : (start : string, end : string) => {
        success : true,
        error : undefined
    };
}

interface StateProps extends RouteChildrenProps {
    events : Event[],
    filters: EventFilters,
    user: Account,
    resources: Resource[],
    accounts: Account[],
    templates: Template[]
}

interface EventCalendarState {
    range: DateRange,
    month: Moment,
    monthText: string,
    modalIsOpen: boolean,
    modalType: string,
    modalEvent?: Event,
    modalEvents?: Event[]
}

type EventCalendarProps = DispatchProps & StateProps;

export class PureEventCalendar extends PureComponent<EventCalendarProps, EventCalendarState> {

    unlisten = () => {}

    constructor(props : any) {
        super(props);

        const monthText = moment().format('YYYY-MM');
        const start = moment(monthText, 'YYYY-MM').startOf('month').day(0);
        const end = moment(monthText, 'YYYY-MM').endOf('month').day(6);
        const range = moment.range(start, end);
        this.state = {
            range: range,
            month: start,
            monthText: monthText,
            modalIsOpen: false,
            modalType: 'event',
            modalEvent: undefined,
            modalEvents: []
        }
    }

    private changeMonth(direction: number, month?: string) {
        const { refreshEventsDateRange } = this.props;

        const monthText = month || this.state.monthText;
        const start = moment(monthText, 'YYYY-MM').startOf('month').add(direction, 'month').day(0);
        const end = moment(monthText, 'YYYY-MM').endOf('month').add(direction,'month').day(6);
        const range = moment.range(start, end);
        this.setState({
            range: range,
            month: start,
            monthText: range.center().format('YYYY-MM')
        });
        refreshEventsDateRange(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    }

    handleOpenEventModal = (event: Event) => {
        this.setState({ modalIsOpen: true, modalType:'event', modalEvent: event });
    }

    handleOpenResourcesModal = (events: Event[]) => {
        this.setState({ modalIsOpen: true, modalType:'resources', modalEvents: events });
    }

    handleCloseModal = () => {
        this.setState({ modalIsOpen: false });
    }

    goToEventEdit = (id: string) => {
        this.handleCloseModal();
        this.props.history.push(`/events/edit/`+id);
    }

    duplicateEvent = (id: string) => {
        this.handleCloseModal();
        this.props.history.push(`/events/duplicate/`+id);
    }

    public componentDidMount() {
        this.changeMonth(0, moment().format('YYYY-MM'));
    }

    public render() : ReactNode {

        const { events, filters, user, resources, accounts, templates } = this.props;
        const { range, monthText } = this.state;

        const filteredEvents = applyFilters(events, filters);

        return (
            <Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            Event Calendar
                            {user && (user.role !== EAccountRoles.viewer) && <EventAdder />}
                        </h3>
                        <EventsFilter resources={resources} accounts={accounts} templates={templates} />
                    </div>
                    <div className="panel-body">
                        <div id="calendarApp" className="calendar--app fc fc-unthemed fc-ltr">
                            <div className="month-navigation fc-toolbar fc-header-toolbar">
                                <div className="fc-left"></div>
                                <div className="fc-right"></div>
                                <div className="fc-center">
                                    <button type="button" className="fc-prev-button fc-button fc-state-default fc-corner-left fc-corner-right" aria-label="prev" onClick={() => this.changeMonth(-1)}><span className="fc-icon fc-icon-left-single-arrow"></span></button>
                                    <button type="button" className="fc-next-button fc-button fc-state-default fc-corner-left fc-corner-right" aria-label="next" onClick={() => this.changeMonth(1)}><span className="fc-icon fc-icon-right-single-arrow"></span></button>
                                    <h2 className="current">{ moment(monthText, 'YYYY-MM').format('MMMM YYYY') }</h2>
                                </div>
                                <div className="fc-clear"></div>
                            </div>
                        </div>

                        <div id="event-calendar" className="event-calendar">
                            <div className="daylabel">Sun</div>
                            <div className="daylabel">Mon</div>
                            <div className="daylabel">Tue</div>
                            <div className="daylabel">Wed</div>
                            <div className="daylabel">Thu</div>
                            <div className="daylabel">Fri</div>
                            <div className="daylabel">Sat</div>

                            { Array.from(range.by('days')).map(day => { return (
                                <EventCalendarDay
                                    key={day.format('YYYY-MM-DD')} 
                                    filteredEvents={filteredEvents.filter(e => { 
                                        return e.scheduling.date == day.format('YYYY-MM-DD');
                                    })}
                                    events={events.filter(e => { 
                                        return e.scheduling.date == day.format('YYYY-MM-DD');
                                    })}
                                    dayNumber={day.format('D')}
                                    onEventClick={this.handleOpenEventModal}
                                    onResourcesClick={this.handleOpenResourcesModal}
                                    />
                            )})}

                        </div>
                        <EventStatusKey />
                        <ReactModal
                            isOpen={this.state.modalIsOpen}
                            appElement={ (document.getElementById('root'))!}
                            className="day-popup panel"
                            overlayClassName="day-popup-overlay"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={this.handleCloseModal}
                        >
                            { this.state.modalType === 'event' &&
                                (typeof(this.state.modalEvent) === 'undefined' ? 'No event selected' :
                                <EventPopup
                                    event={this.state.modalEvent!}
                                    close={this.handleCloseModal}
                                    edit={this.goToEventEdit}
                                    duplicate={this.duplicateEvent}
                                />)
                            }
                            { this.state.modalType === 'resources' &&
                                ((typeof(this.state.modalEvents) === 'undefined' || this.state.modalEvents.length == 0) ? 'Day has no events' :
                                <EventResourcesPopup
                                    events={this.state.modalEvents!}
                                    close={this.handleCloseModal}
                                />)
                            }
                        </ReactModal>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({events, filters, user, resources, accounts, templates } : StoreState) => {
    return {events: events, filters: filters, user: user, resources: resources, accounts: accounts, templates:templates };
};

const mapDispatchToProps = {
    refreshEventsDateRange
};

export const EventCalendar = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureEventCalendar)as any);
