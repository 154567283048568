import React, { PureComponent, ReactNode, Fragment } from "react";
import {Event, StoreState, Resource} from '../../types/StoreState';
import { formatTime } from "../utility/functions";
import { connect } from "react-redux";
import '../../css/EventResourcesPopup.css';

interface StoreProps {
    resources: Resource[];
}

interface LocalProps {
    events: Event[];
    close: () => void;
}

interface LocalState { }

type EventResourcesPopupProps = StoreProps & LocalProps;

class UnconnectedEventResourcesPopup extends PureComponent<EventResourcesPopupProps, LocalState> {

    public render(): ReactNode {
        
        const { events, resources, close } = this.props;

        let usage:any = [];
        resources.forEach(r => {
            const e = events.filter(e => {
                return (e.status === 'booked' && e.resources.map(er => er.id).includes(r.id));
            });

            if (e.length === 0) return;

            let allocations:any = [];
            e.forEach(e => {
                const allocation = e.resources.find(er => er.id === r.id);
                allocation && allocations.push({
                    id: e.id,
                    name: e.name,
                    owner: e.owner,
                    quantity: allocation.quantity,
                    scheduling: allocation.scheduling
                })
            });

            usage.push({
                ...r,
                events: allocations.sort((a:any, b:any) => a.scheduling.reserveStart == b.scheduling.reserveStart ? 0 : +(a.scheduling.reserveStart > b.scheduling.reserveStart) || -1)
            });
        });
        usage.sort((a:any, b:any) => a.name == b.name ? 0 : +(a.name > b.name) || -1);

        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title">Booked Resources
                    <span className={`badge badge-success`}>{events[0].scheduling.date}</span>
                    </h5>
                    <button type="button" className="close" onClick={close}>×</button>
                </div>
                <div className="modal-body resources usage">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>Schedule</th>
                                <th>Quantity</th>
                                <th>Event</th>
                                <th>Owner</th>
                            </tr>
                            {usage.map((resource:any) => <Fragment key={resource.id}>
                            <tr className="resource">
                                <td colSpan={4}>
                                    <h4>{resource.name}</h4>
                                    <div>{resource.type}, max: {resource.maximum}, {<a href={`mailto:`+resource.email}>contact</a>}</div>
                                </td>    
                            </tr>                                
                            {resource.events.map((e:any) => 
                            <tr className="event" key={resource.id+'|'+e.id}>
                                <td>{formatTime(e.scheduling.reserveStart)} - {formatTime(e.scheduling.reserveEnd)}</td>
                                <td>{e.quantity}</td>
                                <td>{e.name}</td>
                                <td><a href={`mailto:`+e.owner.email}>{e.owner.name}</a></td>
                            </tr>)}
                            </Fragment>)}
                        </tbody>
                    </table>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" onClick={close}>Close</button>
                </div>
            </>
        );
    }
}

// Connector
const mapStateToProps = ({resources} : StoreState) => {
    return {resources};
};

const mapDispatchToProps = {};

export const EventResourcesPopup = connect(mapStateToProps, mapDispatchToProps)(UnconnectedEventResourcesPopup);
