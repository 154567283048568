import { applyMiddleware, compose } from 'redux';
import { createStore, Reducer, StoreEnhancer } from 'redoodle';
import { rootReducer } from './reducers/Reducers';
import { StoreState, defaultState } from './types/StoreState';
import thunk from 'redux-thunk';

//const enhancers = [];
const middleware = [
    thunk
];

const composedEnhancers: StoreEnhancer = compose(
    applyMiddleware(...middleware),
    //...enhancers
) as StoreEnhancer;

export const configureStore = (initialState?: StoreState) => {
    initialState = initialState || defaultState;
    return createStore<StoreState>(
        rootReducer as Reducer<StoreState>,
        initialState,
        composedEnhancers
    );
};
