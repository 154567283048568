import React, { PureComponent, ReactNode, Fragment } from "react";
import { Formik, FormikActions, FormikProps, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Template, StoreState, Resource } from '../../types/StoreState';
import { addTemplate, updateTemplate, getTemplate } from "../../reducers/TemplatesReducers";
import { refreshResources } from "../../reducers/ResourcesReducers";
import { withRouter, match, RouteChildrenProps } from "react-router";
import { connect } from "react-redux";
import { ResultMessage } from "../../types/Messaging";
import { ResourceSelect } from "../interface/ResourceSelect";
import { FormGroupInputText } from "../interface/FormGroupInputText";
import { TemplateResourceAllocationRow } from "./TemplateResourceAllocationRow";

const FormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Template name is required'),
    resources: Yup.array()
        .ensure()
        .of(Yup.object().shape({
            quantity: Yup.number()
                .min(0, 'Must be 0 or positive')
        }))
});

interface UrlParams {
    id: string;
}

// Pure Component
interface DispatchProps {
    addTemplate: (template: Template) => void;
    updateTemplate: (template: Template) => void;
    getTemplate: (id: string) => ResultMessage;
    refreshResources: () => ResultMessage;
}

interface StateProps extends RouteChildrenProps {
    templates: Template[],
    resources: Resource[],
    match: match<UrlParams>
}

interface TemplateFormState {
    template?: Template
}

type TemplateFormProps = DispatchProps & StateProps;

const emptyResource:Resource = {
    id: '',
    name: '',
    type: ''
}

export class PureTemplateForm extends PureComponent<TemplateFormProps, TemplateFormState> {
    constructor(props: any) {
        super(props);
        const { match: { params: { id } }, templates } = this.props;
        this.state = {
            template: templates.find(template => template.id === id)
        };
    }

    public render(): ReactNode {

        const { match: { params: { id } }, resources, addTemplate, updateTemplate, history } = this.props;
        const { template } = this.state;
        
        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">{id ? `Edit` : `Add`} Program</h3>
                    </div>

                    <div className="panel-content">
                        <Formik
                        enableReinitialize={true}
                        initialValues={template || {
                            id: '',
                            name: '',
                            resources: []
                        }}
                        validationSchema={FormSchema}
                        onSubmit={async (values: Template, actions: FormikActions<Template>) => {
                            if (template) {
                                const result = await updateTemplate(values);
                            } else {
                                const result = await addTemplate(values);
                            }
                            actions.setSubmitting(false);
                            history.push('/templates');
                        }}
                        render={(props: FormikProps<Template>) => {
                            
                            const filteredResources = resources.filter((resource) => props.values.resources.map((r) => r.id).indexOf(resource.id) === -1);

                            return (
                            <Form>
                                <Field component={FormGroupInputText}
                                    name="name"
                                    label="Template Name"
                                    touched={props.touched.name}
                                    errors={props.errors.name}
                                    help={`A descriptive name, like "Rotunda Garden Party" or "Swanky Soiree"`}
                                />

                                <div className="label-text">Resource Allocations</div>
                                <FieldArray
                                    name="resources"
                                    render={(helpers) => (
                                        <Fragment>
                                            <div className="form-group">
                                                <table className="table"><tbody>
                                                    <tr>
                                                        <th className="quantity-column">Quantity</th>
                                                        <th>Resource (type)</th>
                                                        <th>Maximum Available</th>
                                                        <th>Contact Email</th>
                                                    </tr>
                                                    {props.values.resources.length ? props.values.resources.map((resource, index) => {
                                                        return (
                                                            <TemplateResourceAllocationRow
                                                                key={index}
                                                                index={index}
                                                                resource={resource}
                                                                r={resources.find(r => r.id === resource.id) || emptyResource}
                                                            />
                                                        )
                                                    }) : <tr><td colSpan={4}>No resources have been allocated</td></tr>}
                                                </tbody></table>
                                                {props.values.resources.length ?
                                                    <small className="form-text text-muted">Set quantity to zero to remove a resource</small>
                                                    : ''}
                                            </div>
                                            <div className="form-group add-form form-inline">
                                                <Field component={ResourceSelect}
                                                    resources={filteredResources}
                                                    clickHandler={(id: string) => {
                                                        const resource = resources.find(r => r.id === id) || emptyResource;
                                                        if (resource) helpers.push({ ...resource, quantity: 1, new: true });
                                                    }}
                                                    key={filteredResources.length}
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                />

                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </Form>
                        )}} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ templates, resources }: StoreState) => {
    return { templates: templates, resources: resources };
};

const mapDispatchToProps = {
    addTemplate,
    updateTemplate,
    getTemplate,
    refreshResources
};

export const TemplateForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureTemplateForm) as any);
