import * as React from 'react';

// Component
import { Component, ReactNode } from 'react';
import { Event } from '../../types/StoreState';
import { Link } from 'react-router-dom';
import { formatTime } from '../utility/functions';

// Pure Component
interface DispatchProps {}

interface StateProps {
    event: Event;
    isOwner: boolean;
    mayAddEvent: boolean;
    onClick: (event: Event) => void;
}

type EventRowProps = DispatchProps & StateProps;

export class EventRow extends Component<EventRowProps> {
    public render(): ReactNode {
        const { event, isOwner, mayAddEvent, onClick } = this.props;
        return (
            <tr>
                <td className="actions">
                    <button className="btn btn-primary btn-sm" onClick={() => onClick(event)}>View</button>
                    { mayAddEvent ? (<Link to={`/events/duplicate/${event.id}`} className="btn btn-primary btn-sm">Dup</Link>) : `` }
                    { isOwner ? (<Link to={`/events/edit/${event.id}`} className="btn btn-primary btn-sm">Edit</Link>) : `` }
                </td>
                <td>
                    {event.name} <span className={`badge `+event.status}>{event.status}</span>
                </td>
                <td>
                    {event.scheduling.date}, {formatTime(event.scheduling.eventStart)}-{formatTime(event.scheduling.eventEnd)}
                </td>
                <td>
                    <a href={`mailto:`+event.owner.email}>{event.owner.name}</a>
                </td>
            </tr>
        );
    }
}
