import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form, Field, FormikActions, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faFilter, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import { SetFiltersActionType } from '../../reducers/EventFiltersReducers';
import { statusOptions, IGroupedOptions, IBasicOption, StoreState, Resource, EventOptions, Account, EFilterGroups, EventFilters, Template } from '../../types/StoreState';
import '../../css/EventFilter.css';
import { getFiltersFromResources } from '../utility/functions';

interface FormValues {
    filter : string,
    group : EFilterGroups
}

type FilterProps = {
    resources: Resource[],
    accounts: Account[],
    templates: Template[]
}

const formValues = {
    filter: '',
    group: EFilterGroups.status
};

const ls = require('local-storage');

const EventFilter = (props:FilterProps) => {

    const storeDispatch = useDispatch();

    const [filters, setFilters] = useState<EventFilters>(useSelector((state:StoreState) => state.filters));
    const [filterOptions, setFilterOptions] = useState<EventOptions>({
        status: statusOptions,
        resource: getFiltersFromResources(props.resources),
        owner: [],
        template: []
    });
    const [isOpen, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const o:IBasicOption[] = props.accounts.map<IBasicOption>(o => { return {value: o.username, label: o.name}})
        const t:IBasicOption[] = props.templates.map<IBasicOption>(t => { return {value: t.id, label: t.name}})
        const r:IGroupedOptions = getFiltersFromResources(props.resources);
        setFilterOptions({...filterOptions, owner:o, template:t, resource:r });
    }, [props.resources, props.templates, props.accounts]);

    useEffect(() => {
        ls.set('filters', filters);
        storeDispatch({
            type: SetFiltersActionType,
            payload: filters
        })
    }, [filters]);

    const getOptions = (key: string) => {
        switch (key) {
            case 'status': {
                const o = filterOptions.status.filter(s => !filters.status.includes(s.value));
                return o.map((item, index) => { return (
                    <option
                        key={index}
                        value={item.value}
                        >{item.label}
                    </option>
                )});
                break;
            }

            case 'resource': {
                let types:string[] = [];
                for (let prop in filterOptions.resource) {
                    if (filterOptions.resource.hasOwnProperty(prop)) {
                        types.push(prop);
                    }
                }
                return (types.map((type) => {
                    const options = filterOptions.resource[type].filter(o => !filters.resource.includes(o.value));
                    return (options.length > 0) ? (
                        <optgroup key={type} label={type}>
                            {options.map((r:IBasicOption) => (
                                <option key={r.value} value={r.value}>{r.label}</option>
                            ))}
                        </optgroup>) : ``;
                }));
            }

            case 'owner': {
                const o = filterOptions.owner.filter(s => !filters.owner.includes(s.value));
                return o.map((item, index) => { return (
                    <option
                        key={index}
                        value={item.value}
                        >{item.label}
                    </option>
                )});
                break;
            }

            case 'template': {
                const o = filterOptions.template.filter(s => !filters.template.includes(s.value));
                return o.map((item, index) => { return (
                    <option
                        key={index}
                        value={item.value}
                        >{item.label}
                    </option>
                )});
                break;
            }

        }
    }

    const removeFilter = (group: EFilterGroups, value: string) => {
        let newGroup:Set<string> = new Set(filters[group]);
        newGroup.delete(value);
        setFilters({...filters, [group]: Array.from(newGroup)});
    }

    const onSubmit = ({filter, group}:FormValues, {setValues}:FormikActions<FormValues>) => {
        if (filter) {
            setFilters({...filters, [group]: Array.from(new Set(filters[group]).add(filter))});
            setValues({filter: '', group:group});
        }
    };

    return ( <>
        <div className="filter-selected">
            <span className="filter-toggle badge badge-secondary" onClick={() => setOpen(!isOpen)}><FontAwesomeIcon icon={faFilter} size="xs" />
                <span><FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown}/></span>
            </span>
            
            { (filters.status.length > 0) || (filters.resource.length > 0) || (filters.owner.length > 0) || (filters.template.length > 0) || (<span className="filters-none">No filters selected</span>) }

            {(filters.status.length > 0) && <span className="filter-group"><span className="filter-group-label badge">Statuses:</span>
                {filters.status.map(item => {
                const matching = filterOptions.status.filter(o => o.value === item);
                    return (matching.length > 0) ? (
                    <span className="badge badge-secondary" key={`status|`+item}>{matching[0].label}
                        <span onClick={() => removeFilter(EFilterGroups.status, item)}><FontAwesomeIcon icon={faTimes}/></span>
                    </span>) : ``;
            })}</span>}

            { (filters.status.length > 0) && (filters.resource.length > 0) && <span className="connector badge">and</span> }

            {(filters.resource.length > 0) && <span className="filter-group"><span className="filter-group-label badge">Resources:</span>
                {filters.resource.map(item => {
                    const matching = props.resources.filter(o => o.id === item);
                    return (matching.length > 0) ? (
                    <span className="badge badge-secondary" key={`resource|`+item}>{matching[0].name}
                        <span onClick={() => removeFilter(EFilterGroups.resource, item)}><FontAwesomeIcon icon={faTimes}/></span>
                    </span>) : ``;
            })}</span>}

            { ((filters.status.length > 0) || (filters.resource.length > 0)) && (filters.owner.length > 0) && <span className="connector badge">and</span> }

            {(filters.owner.length > 0) && <span className="filter-group"><span className="filter-group-label badge">Owners:</span>
                {filters.owner.map(item => {
                    const matching = filterOptions.owner.filter(o => o.value === item);
                    return (matching.length > 0) ? (
                    <span className="badge badge-secondary" key={`owner|`+item}>{matching[0].label}
                        <span onClick={() => removeFilter(EFilterGroups.owner, item)}><FontAwesomeIcon icon={faTimes}/></span>
                    </span>) : ``;
            })}</span>}

            { ((filters.status.length > 0) || (filters.resource.length > 0) || (filters.owner.length > 0)) && (filters.template.length > 0) && <span className="connector badge">and</span> }

            {(filters.template.length > 0) && <span className="filter-group"><span className="filter-group-label badge">Program:</span>
                {filters.template.map(item => {
                    const matching = filterOptions.template.filter(o => o.value === item);
                    return (matching.length > 0) ? (
                    <span className="badge badge-secondary" key={`template|`+item}>{matching[0].label}
                        <span onClick={() => removeFilter(EFilterGroups.template, item)}><FontAwesomeIcon icon={faTimes}/></span>
                    </span>) : ``;
            })}</span>}

        </div>
        {isOpen && <div className='filter-block'>
            <div className="filter-selection">
                <Formik initialValues={formValues} onSubmit={onSubmit}>
                    {({values, setFieldValue, handleChange} : FormikProps < FormValues >) => (
                        <Form className="form-inline">
                            <Field
                                component="select"
                                name="group"
                                className="form-control filter-select mr-2"
                            >
                                {Object
                                    .keys(filterOptions)
                                    .map(key => <option key={key} value={key}>{key==='template' ? 'Program' : key}</option>)}
                            </Field>
                            <Field
                                component="select"
                                name="filter"
                                className="form-control filter-select mr-2">
                                <option disabled value="">Select option</option>
                                {getOptions(values.group)}
                            </Field>
                            <button className="btn btn-primary btn-sm" type="submit">Add</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>}
    </>);
};

export default React.memo(EventFilter);
