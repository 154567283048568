import * as React from 'react';

// Component
import { PureComponent, ReactNode, Fragment } from 'react';

// Connector
import { connect } from 'react-redux';
import { Account, StoreState } from '../../types/StoreState';
import { refreshAccounts, accountsReducer } from '../../reducers/AccountsReducers';
import { AccountRow } from './AccountRow';
import { withRouter, RouterProps } from 'react-router';
import { Link } from 'react-router-dom';

// Pure Component
interface DispatchProps {
    refreshAccounts: () => void;
}

interface StateProps extends RouterProps {
    accounts: Account[]
}

type AccountTableProps = DispatchProps & StateProps;

export class PureAccountTable extends PureComponent<AccountTableProps> {

    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        const { refreshAccounts } = this.props;
        refreshAccounts();
    }

    public render(): ReactNode {

        const { accounts } = this.props;

        return (
            <React.Fragment>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Accounts
                            <Link to="/users/add" className="float-right btn btn-primary btn-sm">Add</Link>
                        </h3>
                    </div>

                    <div className="panel-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="actions">Actions</th>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accounts.map(account => <AccountRow key={account.username} account={account} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// Connector
const mapStateToProps = ({ accounts }: StoreState) => {
    return { accounts: accounts };
};

const mapDispatchToProps = {
    refreshAccounts
};

export const AccountTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(PureAccountTable) as any);
